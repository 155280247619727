var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay cursor-default",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_c('div',{staticClass:"feedback xs:p-0",on:{"click":function($event){$event.stopPropagation();void 0}}},[_vm._l((_vm.questions),function(q,i){return _c('div',{key:i},[(q.type === 'heading')?_c('FeedbackTitle',{attrs:{"title":q.text},on:{"close":function($event){return _vm.$emit('close')}}}):_vm._e(),(q.type === 'ratingstars')?_c('div',{staticClass:"feedback_question-wrapper",class:[_vm.submission.rating ? '' : 'mb-0']},[_c('div',{staticClass:"feedback_question",attrs:{"data-test":"question-rating-feedback"}},[_vm._v(" "+_vm._s(q.text)+" ")]),_c('Rating',{attrs:{"selected-rating":_vm.submission[q.submissionProp],"rating-states":q.states},on:{"set-rating":function($event){return _vm.setRating(
              $event,
              q.submissionProp,
              q.submissionNameProp,
              q.states
            )}}})],1):_vm._e(),(q.type === 'options' && _vm.submission[q.propNeeded])?_c('div',[_c('Options',{attrs:{"multiple":q.allowMultipleSelection,"options-data":q.optionsData[_vm.submission[q.propNeeded]],"prop-model":_vm.submission[q.submissionProp]},on:{"update":function($event){_vm.submission[q.submissionProp] = $event}}}),(
            _vm.submission[q.submissionProp].includes('Other') &&
              q.otherOptionisSelectedQuestion
          )?_c('div',{staticClass:"feedback_question-wrapper"},[_c('TextArea',{attrs:{"placeholder":q.otherOptionisSelectedPlaceholder,"type":'MultiLine',"question":{ id: q.otherOptionisSelectedSubmissionProp }},on:{"update-answer":function($event){_vm.submission[q.otherOptionisSelectedSubmissionProp] = $event}}})],1):_vm._e()],1):_vm._e(),(q.type === 'textarea' && _vm.submission.rating === 5)?_c('div',{staticClass:"feedback_question-wrapper"},[_c('TextArea',{attrs:{"placeholder":q.label,"type":'MultiLine',"question":{ id: q.submissionProp }},on:{"update-answer":function($event){_vm.submission[q.submissionProp] = $event}}})],1):_vm._e()],1)}),(_vm.submission.rating)?_c('FeedbackFooterActions',{attrs:{"submission-result":_vm.submissionResult,"submission":_vm.submission},on:{"submit":_vm.submitFeedback}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }