import { ref, onBeforeUnmount, nextTick, onMounted, computed } from '@vue/composition-api'

export default function useScreen () {
  const bp = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280
  }
  const windowWidth = ref(1024)
  const isResizing = ref(false)
  const isSmallScreen = computed(() => { return window.innerWidth < bp.md })

  const onResize = () => {
    isResizing.value = true
    windowWidth.value = window.innerWidth
    setTimeout(() => {
      isResizing.value = false
    }, 200)
  }

  onMounted(() => {
    nextTick(() => {
      onResize()
      window.addEventListener('resize', onResize)
    })
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize)
  })

  return {
    windowWidth, isResizing, isSmallScreen
  }
}
