import store from '@/store'
import capitalize from 'lodash/capitalize'
import { quoteModule } from '@/store/store-accessor'
import { CheckoutMutations } from '@/store/checkout-module/mutation-types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Quote } from '@/models/quote'
import { Address } from '@/models/questionnaire'
import { isPolicyBound } from '@/helpers/quote'
import { BrizaCollect, CreditCard, CreditCardType } from '@briza/collect'
import { CreatePolicyRequest } from '@/models/policy'

@Module({ dynamic: true, store, name: 'CheckoutModule' })
export default class CheckoutModule extends VuexModule {
  public brizaCollect: BrizaCollect | undefined

  public errorMessage = ''
  public emailAddress = ''

  public billingAddress: Address = {
    street: '',
    secondary: '',
    city: '',
    region: '',
    postalCode: '',
    county: '',
    country: 'US'
  }

  public creditCard: CreditCard = {
    cvc: '',
    number: '',
    name: '',
    expYear: '',
    expMonth: '',
    cardType: CreditCardType.Visa
  }

  @Action
  public setBrizaCollect (collector: BrizaCollect): void {
    this.brizaCollect = collector
  }

  @Mutation
  private [CheckoutMutations.SET_EMAIL_ADDRESS] (emailAddress: string): void {
    this.emailAddress = emailAddress
  }

  @Action({ commit: CheckoutMutations.SET_EMAIL_ADDRESS })
  public setEmailAddress (emailAddress: string): string {
    return emailAddress
  }

  @Mutation
  private [CheckoutMutations.SET_BILLING_ADDRESS] (billingAddress: Address): void {
    this.billingAddress = billingAddress
  }

  @Action({ commit: CheckoutMutations.SET_BILLING_ADDRESS })
  public setBillingAddress (billingAddress: Address): Address {
    return billingAddress
  }

  @Mutation
  private [CheckoutMutations.SET_ERROR_MESSAGE] (errorMessage: string): void {
    this.errorMessage = errorMessage
  }

  @Action({ commit: CheckoutMutations.SET_ERROR_MESSAGE })
  public setErrorMessage (errorMessage = ''): string {
    return errorMessage
  }

  @Action({ commit: CheckoutMutations.SET_ERROR_MESSAGE })
  public handleCreditCardError (error: any): string {
    if (error.cardNumber?.errorMessages) {
      return `Credit Card ${error.cardNumber.errorMessages.join(',')}`
    } else if (error.cardCvc?.errorMessages) {
      return `CVC ${error.cardCvc.errorMessages.join(',')}`
    } else if (error.expMonth?.errorMessages) {
      return `Expiry Month ${error.cardCvc.errorMessages.join(',')}`
    } else if (error.expYear?.errorMessages) {
      return `Expiry Year ${error.cardCvc.errorMessages.join(',')}`
    }

    return ''
  }

  @Action
  public handleError (error: any): void {
    if (error.cardNumber?.errorMessages) {
      this.handleCreditCardError(error)
    } else {
      const message = capitalize(error?.message) || 'There was an error processing your payment'
      this.setErrorMessage(message)
    }
  }

  @Action({ rawError: true })
  public async postPolicy (
    { selectedQuote, collectPayment }: { selectedQuote: Quote, collectPayment: boolean }
  ): Promise<void> {
    if (!this.brizaCollect) throw new Error('briza collect not bound, ensure to call "setBrizaCollect"')

    const policyRequest: CreatePolicyRequest = {
      quoteId: selectedQuote.id,
      installmentFrequency: quoteModule.quoteSelections[0].installmentFrequency,
      skipPayment: true
    }

    if (collectPayment) {
      const token = await this.brizaCollect.tokenize()
      policyRequest.skipPayment = false
      policyRequest.payment = {
        ...this.creditCard,
        ...token.payment,
        email: this.emailAddress,
        address: { ...this.billingAddress }

      }
    }

    const policy = await quoteModule.createPolicy(policyRequest)

    if (isPolicyBound(policy)) {
      quoteModule.setPolicies(policy)
    } else {
      await quoteModule.retryPolicies({
        retryCounter: 1,
        quoteId: selectedQuote?.id,
        policy
      })
    }
  }
}
