export const enum PreApplicationErrors {
  NO_APPETITE = 'pre_application.no_appetite',
  NO_WORKERS_COMPENSATION_AVAILABILITY ='pre_application.no_workers_compensation_availability',
  INVALID_ANSWER_TYPE = 'pre_application.invalid_answer_data_type',
  INVALID_ANSWER_VALUE = 'pre_application.invalid_answer_value',
  REQUIRED_ANSWER = 'pre_application.missing_required_answer',
  UNEXPECTED_ANSWER = 'pre_application.unexpected_answer'
}

export const enum ApplicationErrors {
  INVALID_ANSWER_VALUE = 'application.invalid_answer_value',
}

export const enum GeneralErrors {
  GENERAL_UNAUTHORIZED = 'general.unauthorized',
  GENERAL_INVALID_PARAMETERS = 'general.invalid_parameters'
}

export const enum CoverageErrors {
  MISSING_REQUIRED_ANSWER = 'coverage.missing_required_answer'
}

export class TokenNotFoundError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'TokenNotFoundError'
    this.message = message
  }
}
