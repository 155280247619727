




















import IconBase from '@/components/icon-library/icon-base.vue'
import CartIcon from '@/components/icon-library/cart.vue'
import { cartModule } from '@/store/store-accessor'
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'CartButton',
  components: { CartIcon, IconBase },
  setup () {
    const numberOfItems = computed(() => {
      return cartModule.cartItems.length
    })
    return { numberOfItems }
  }
})

