import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'
import LinkNotFound from '@/views/LinkNotFound.vue'
import { ROUTES } from './routes'
import { authenticationMiddleware } from './middleware/authentication'
import { resumptionMiddleware } from './middleware/resumption'
import { IApiClient } from '@/api/abstract/IApiClient'
import { defaultValuesMiddleware } from './middleware/default-values'

const routes = [
  {
    name: ROUTES.HOME,
    path: '/',
    component: Home,
    meta: {
      title: 'Home'
    },
    beforeEnter (to: Route, from: Route, next: Function) {
      if (window.location.hostname.split('.')[0] === 'appalachian') {
        window.location.replace('https://auiagents.com/Router/Briza?programCode=brokerage')
      }
      next()
    }
  },
  { path: '/pre-application', redirect: '/' },
  { path: '/application', redirect: '/' },
  {
    name: ROUTES.PRE_APPLICATION,
    path: '/pre-application/:id',
    props: true,
    component: () => import(/* webpackChunkName: "pre-application" */ '@/views/PreApplication.vue'),
    meta: {
      title: 'Pre-Application'
    }
  },
  {
    name: ROUTES.APPLICATION,
    path: '/application/:id',
    props: true,
    component: () => import(/* webpackChunkName: "application" */ '@/views/Application.vue'),
    meta: {
      title: 'Application'
    }
  },
  {
    name: ROUTES.REVIEW,
    path: '/application/:id/review',
    props: true,
    component: () => import(/* webpackChunkName: "quotes" */ '@/views/Review.vue'),
    meta: {
      title: 'Review'
    }
  },
  {
    name: ROUTES.QUOTES,
    path: '/application/:id/quotes',
    props: true,
    component: () => import(/* webpackChunkName: "quotes" */ '@/views/Quotes.vue'),
    meta: {
      title: 'Quotes'
    }
  },
  {
    name: ROUTES.ACKNOWLEDGMENTS,
    path: '/application/:id/quotes/:quoteid/acknowledgements',
    props: true,
    component: () => import(/* webpackChunkName: "acknowledgements" */ '@/views/Acknowledgements.vue'),
    meta: {
      title: 'Acknowledgements'
    }
  },
  {
    name: ROUTES.CHECKOUT,
    path: '/application/:id/checkout',
    props: true,
    component: () => import(/* webpackChunkName: "checkout" */ '@/views/Checkout.vue'),
    meta: {
      title: 'Checkout'
    }
  },
  {
    name: ROUTES.CHECKOUT_CONFIRMATION,
    path: '/application/:id/policy',
    props: true,
    component: () => import(/* webpackChunkName: "policy" */ '@/views/CheckoutConfirmation.vue'),
    meta: {
      title: 'Insured'
    }
  },
  {
    name: ROUTES.EXPIRED,
    path: '/expired',
    component: () => import(/* webpackChunkName: "expired" */ '@/views/Expired.vue'),
    meta: {
      title: 'Expired'
    }
  },
  {
    name: ROUTES.PAGE_NOT_FOUND,
    path: '/page-not-found',
    alias: '*',
    component: NotFound,
    meta: {
      title: 'Page not found'
    }
  },
  {
    name: ROUTES.LINK_NOT_FOUND,
    path: '/link-not-found',
    alias: '*',
    component: LinkNotFound,
    meta: {
      title: 'Link not found'
    }
  }
]

/**
 * Create a vue router.
 * @param apiClient Api client instance.
 */
export function createRouter (
  apiClient: IApiClient
): VueRouter {
  Vue.use(VueRouter)

  const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes
  })

  // middlewares
  router.beforeEach(
    authenticationMiddleware(apiClient, Vue.$logger)
  )
  router.beforeEach(
    resumptionMiddleware(Vue.$logger)
  )
  router.beforeEach(defaultValuesMiddleware())

  return router
}
