export interface Link {
  email?: string
  meta: LinkMeta
}

interface LinkMeta {
  progress: string,
  quoteId?: string
}

export interface LinkResponse {
  id: string
  email: string
  meta: LinkMeta
  type: 'policyholder' | 'brokerage'
  activeToken?: string
  createdAt: string
  updatedAt: string
}

export enum Progress {
  preApplication = 'pre-application',
  application = 'application',
  quotes = 'quotes',
  review = 'review',
  payment = 'payment'
}
