











































































import { defineComponent, computed, ref, reactive } from '@vue/composition-api'
import { binderModule } from '@/store/store-accessor'
import { ROUTES } from '@/router/routes'
import { MoreVerticalIcon } from 'vue-feather-icons'
import VueRouter from 'vue-router'

export default defineComponent({
  name: 'TheNavbarSubMenu',
  components: {
    MoreVerticalIcon
  },
  props: {
    showSaveForLater: {
      type: Boolean,
      default: false
    }
  },
  setup (props, ctx) {
    const showSubMenu = ref(false)
    const router = reactive<VueRouter>(ctx.root.$router)
    const submenu = ref<any>(null)

    const canRestartApplication = computed(() => {
      return router.currentRoute.path.startsWith('/application/')
    })

    const canShowBusinessInfo = computed(() => {
      return [ROUTES.REVIEW, ROUTES.APPLICATION, ROUTES.QUOTES].includes(router?.currentRoute.name as string)
    })

    const hideSubMenu = () => {
      showSubMenu.value = false
    }

    const handleClickOutsideSubmenu = (event: Event) => {
      if (!submenu.value.contains(event.target as HTMLElement)) {
        showSubMenu.value = false
      }
    }

    const showHideSubMenu = () => {
      showSubMenu.value = !showSubMenu.value
      if (showSubMenu.value) {
        document.addEventListener('click', handleClickOutsideSubmenu)
      } else {
        document.removeEventListener('click', handleClickOutsideSubmenu)
      }
    }

    const restartApplication = async () => {
      const preApplicationId = await binderModule.restartApplication()

      showHideSubMenu()

      return router.push({
        name: ROUTES.PRE_APPLICATION,
        params: { id: preApplicationId }
      })
    }

    const toggleSaveForLater = () => {
      binderModule.setSaveForLater()
      showHideSubMenu()
    }

    return {
      canRestartApplication,
      canShowBusinessInfo,
      hideSubMenu,
      restartApplication,
      showHideSubMenu,
      showSubMenu,
      submenu,
      toggleSaveForLater
    }
  }
})

