export interface FeedbackKeys {
  quotes: string;
  application: string;
}

const defaultRatingStates = ['', 'Disappointing', 'Pretty bad', 'Average', 'Pretty good', 'Excellent']
// empty on index zero to reflect the number of stars for each rating

const defaultQuotesReasons = {
  question: 'What would improve the experience?',
  options: [
    'More quotes',
    'Better prices',
    'More policy info',
    'Payment plans',
    'Other'
  ]
}

const quotesReasons = {
  Disappointing: defaultQuotesReasons,
  'Pretty bad': defaultQuotesReasons,
  Average: defaultQuotesReasons,
  'Pretty good': defaultQuotesReasons,
  Excellent: {}
}

const defaultGenericReasons = {
  question: 'What would improve the experience?',
  options: [
    'Shorter application',
    'Easier questions',
    'More assistance',
    'Other'
  ]
}
const applicationReasons = {
  Disappointing: defaultGenericReasons,
  'Pretty bad': defaultGenericReasons,
  Average: defaultGenericReasons,
  'Pretty good': defaultGenericReasons,
  Excellent: {}
}

export const Questions: { [key: string]: any } = {
  quotes: [
    { type: 'heading', text: 'Thanks for stopping by!' },
    {
      type: 'ratingstars',
      text: 'How was your experience?',
      states: defaultRatingStates,
      submissionProp: 'rating',
      submissionNameProp: 'ratingName'
    },
    {
      type: 'options',
      propNeeded: 'ratingName',
      submissionProp: 'ratingReasons',
      optionsData: quotesReasons,
      otherOptionisSelectedQuestion: 'Share your suggestions',
      otherOptionisSelectedPlaceholder: 'Share your suggestions',
      otherOptionisSelectedSubmissionProp: 'otherRatingReason',
      allowMultipleSelection: true
    },
    {
      type: 'textarea',
      label: 'What did you enjoy?',
      submissionProp: 'fiveStarsEnjoyedReason'
    }
  ],
  application: [
    { type: 'heading', text: 'Thanks for stopping by!' },
    {
      type: 'ratingstars',
      text: 'How was your experience?',
      states: defaultRatingStates,
      submissionProp: 'rating',
      submissionNameProp: 'ratingName'
    },
    {
      type: 'options',
      propNeeded: 'ratingName',
      submissionProp: 'ratingReasons',
      optionsData: applicationReasons,
      otherOptionisSelectedQuestion: 'Share your suggestions',
      otherOptionisSelectedPlaceholder: 'Share your suggestions',
      otherOptionisSelectedSubmissionProp: 'otherRatingReason',
      allowMultipleSelection: true
    },
    {
      type: 'textarea',
      label: 'What did you enjoy?',
      submissionProp: 'fiveStarsEnjoyedReason'
    }
  ]
}
