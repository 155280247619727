































import { Component, Mixins } from 'vue-property-decorator'
import { ZapIcon } from 'vue-feather-icons'
import { brokerageModule } from '@/store/store-accessor'
import LoadingIcon from '@/components/loading-icon/index.vue'
import LoadableMixin from '@/mixins/loadable-mixin'

@Component({
  name: 'LinkNotFound',
  components: {
    ZapIcon,
    LoadingIcon
  }
})

export default class LinkNotFound extends Mixins(LoadableMixin) {
  created () {
    document.title = brokerageModule.brokerage
      ? `${brokerageModule.brokerage.name} | ${this.$route?.meta?.title}`
      : this.$route?.meta?.title
  }
}
