
























































































import { defineComponent, computed, ref, reactive } from '@vue/composition-api'
import { binderModule, feedbackModule } from '@/store/store-accessor'
import { FeedbackKeys, Questions } from '@/models/feedback'
import Rating from './rating.vue'
import TextArea from '@/components/controls/text-area/index.vue'
import Options from './options.vue'
import FeedbackTitle from './heading.vue'
import FeedbackFooterActions from './footer.vue'
export default defineComponent({
  name: 'Feedback',
  components: {
    Rating,
    TextArea,
    Options,
    FeedbackTitle,
    FeedbackFooterActions
  },
  props: {
    feedbackQuestions: {
      type: String as () => keyof FeedbackKeys,
      required: true
    }
  },
  emits: ['close'],
  setup (props, { emit }) {
    const submissionResult = ref('')
    const questions = computed(() => {
      const feedbackKey: keyof FeedbackKeys = props.feedbackQuestions
      return Questions[feedbackKey]
    })
    const submission: { [key: string]: any } = reactive(
      {
        rating: 0,
        ratingFor: props.feedbackQuestions,
        ratingName: '',
        ratingReasons: [],
        otherRatingReason: '',
        fiveStarsEnjoyedReason: '',
        url: document.location.href
      }
    )
    const setRating = (
      rating: number,
      submissionProp: string,
      submissionNameProp: string,
      states: string[]
    ): void => {
      submission[submissionProp] = rating
      submission[submissionNameProp] = states[rating]
      submission.ratingReasons = [] // reset the reason option when rating selected change
    }
    const submitFeedback = async () => {
      submissionResult.value = 'loading'
      try {
        const payload = {
          applicationId: binderModule.application?.id,
          preApplicationId: binderModule.preApplication?.id,
          answers: {
            ratingFor: props.feedbackQuestions,
            ...submission
          }
        }
        await feedbackModule.submit(payload)
      } catch (error: any) {
        submissionResult.value = error
        throw error
      }
      submissionResult.value = 'success'
      setTimeout(() => {
        emit('close')
      }, 1200)
    }
    return {
      submissionResult,
      submitFeedback,
      submission,
      questions,
      setRating
    }
  }
})
