













































import { Component, Vue } from 'vue-property-decorator'
import { CheckIcon, CircleIcon } from 'vue-feather-icons'
import { cartModule, quoteModule, binderModule } from '@/store/store-accessor'
import { Events } from '@/models/events'
import { getProductName } from '@/helpers/products'

import EventBus from '@/event-bus'

@Component({
  name: 'QuoteProductList',
  components: {
    CircleIcon,
    CheckIcon
  }
})
export default class QuoteProductList extends Vue {
  get quotesAvailable () {
    return this.productListComputed.length > 0
  }

  get productsInCartComputed (): string[] {
    return this.cartItems.map((cartItem) => cartItem.slug)
  }

  get cartItems () {
    return cartModule.cartItems
  }

  get productSelectedComputed (): string {
    return quoteModule.productSelected
  }

  get productListComputed () {
    return quoteModule.quoteProductList
  }

  get distinctProductsList () {
    // compare the slugs of the products and return the distinct products
    return this.productListComputed.filter(
      (product, index, comparedArray) =>
        comparedArray.findIndex(
          (comparedProduct) => comparedProduct.slug === product.slug
        ) === index
    )
  }

  get requestedTypesOfInsuranceSlug () {
    return binderModule.answers.RequestedTypesOfInsurance as string[] || null
  }

  get insuranceProductName () {
    return getProductName(this.requestedTypesOfInsuranceSlug ? this.requestedTypesOfInsuranceSlug[0] : '')
  }

  isProductInCart (slug: string) {
    return this.productsInCartComputed.includes(slug)
  }

  handleClick (productSlug: string) {
    EventBus.$emit(Events.SELECT_PRODUCT, productSlug)
  }
}
