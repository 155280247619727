/**
 * Api paginated collection response data wrapper.
 */
export type PaginatedResponse<T> = {
  data: T[]
  pagination: PaginationResponse
}

/**
 * Api request pagination options.
 */
export type PaginationOptions = {
  // Requested page, <1, >
  page: number
  // Requested page size, <1, MAX_SUPPORTED_SIZE>
  size: number
}

/**
 * Api pagination response.
 */
export type PaginationResponse = PaginationOptions & {
  // Total number of pages.
  pages: number
  // Total entities in the set.
  total: number
}

export const defaultPaginationOptions: PaginationOptions = {
  page: 1,
  size: 10
}
