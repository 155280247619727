export type BrokerageProfile = {
  logoUrl: string;
  scripts: BrokerageScript[];
  contacts?: BrokerageContact[];
} & Brokerage;

export interface Brokerage {
  id: string;
  slug: string;
  name: string;
  type: number;
  brandColor: string | null;
  createdAt: string;
}

export interface BrokerageLogo {
  type: string;
  contentType: string;
  url: string;
}

export interface BrokerageContact {
  id: string;
  type: string; // e.g 'primary'
  brokerageId: number;
  phone: string;
  email: string;
  fax: string;
}

export interface BrokerageScript {
  id: string;
  brokerageId: number;
  provider: string;
  configuration: Configuration;
}

export interface Configuration {
  googleAnalyticsId: string;
  facebookId: string;
  googleTagManagerId: string;
}

export enum TrackingProviders {
  FacebookPixel = 'facebook-pixel',
  GoogleTagManager = 'google-tag-manager',
  GoogleAnalytics = 'google-analytics',
  IntercomLiveChat = 'intercom-live-chat',
  HubspotLiveChat = 'hubspot-live-chat',
  SlackLiveChat = 'slack-live-chat',
}
