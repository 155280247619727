import Vue from 'vue'
import { Store } from 'vuex'

/**
 * Services provider Vue plugin.
 * It provide access to services to Vue instances
 * and to the root Vuex store.
 */
export class ServicesProvider {
  private readonly store: Store<unknown>
  private readonly services: {[serviceName: string]: unknown}

  /**
   * @constructor
   * @param store Root Vuex store instance.
   * @param services Services map.
   */
  constructor (store: Store<unknown>, services: {[serviceName: string]: unknown}) {
    this.store = store
    this.services = services
  }

  /**
   * Vue install hook.
   * @param instance Vue instance.
   */
  install (instance: typeof Vue): void {
    const services = this.services
    for (const serviceName in services) {
      /**
       * Define the service getter for Vue instance.
       * (format: $serviceName)
       */
      Object.defineProperty(instance.prototype, `$${serviceName}`, {
        get () { return services[serviceName] }
      })

      /**
       * Define the service getter for Vuex root store instance.
       * (format: $serviceName)
       */
      Object.defineProperty(this.store, `$${serviceName}`, {
        get () { return services[serviceName] }
      })
    }
  }
}
