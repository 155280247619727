import { getModule } from 'vuex-module-decorators'

import BinderModule from './binder-module'
import COBModule from './cob'
import WcClassModule from './wc-class-module'
import QuoteModule from './quote-module'
import UnderwritersModule from './underwriters-module'
import BrokerageModule from './brokerage-module'
import CartModule from '@/store/cart-module'
import FeedbackModule from '@/store/feedback-module'
import CheckoutModule from '@/store/checkout-module'

const checkoutModule = getModule(CheckoutModule)
const binderModule = getModule(BinderModule)
const brokerageModule = getModule(BrokerageModule)
const cobModule = getModule(COBModule)
const wcModule = getModule(WcClassModule)
const quoteModule = getModule(QuoteModule)
const underwritersModule = getModule(UnderwritersModule)
const cartModule = getModule(CartModule)
const feedbackModule = getModule(FeedbackModule)

export {
  binderModule,
  brokerageModule,
  checkoutModule,
  cobModule,
  wcModule,
  quoteModule,
  underwritersModule,
  cartModule,
  feedbackModule
}
