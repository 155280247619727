// TODO: (to VUE 3) delete this, make sure all the components are using 'use/loadable-mixin'

// DO NOT USE THIS FOR NEW FEATURES!
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LoadableMixin extends Vue {
  loading = false
  btnLoading = false

  showLoading () {
    this.loading = true
  }

  hideLoading () {
    this.loading = false
  }

  showBtnLoading () {
    this.btnLoading = true
  }

  hideBtnLoading () {
    this.btnLoading = false
  }
}
