






































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Options',
  props: {
    propModel: {
      type: Array as () => string[] | string,
      default: () => []
    },
    optionsData: {
      type: Object as () => string[],
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update'],
  setup (props, { emit }) {
    const isSelected = (option: string) => {
      return props.propModel.length > 0 ? props.propModel.includes(option) : false
    }
    const toggleOption = (option: string) => {
      const arrayData = [...props.propModel]
      if (isSelected(option)) {
        emit('update', arrayData.filter(e => e !== option))
      } else {
        emit('update', [...arrayData, option])
      }
    }
    return { toggleOption, isSelected }
  }
})
