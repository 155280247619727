import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { WcClassMutations } from '@/store/wc-class-module/mutation-types'
import store from '@/store'

@Module({ dynamic: true, store, name: 'WcClassModule' })
export default class WcClassModule extends VuexModule {
  public error: string | null = null

  // #region Mutations
  @Mutation
  private [WcClassMutations.SET_WC_CLASS_ERROR] (data: string): void {
    this.error = data
  }

  // #region Actions
  @Action({ commit: WcClassMutations.SET_WC_CLASS_ERROR })
  public setWcClassError (data: string): string {
    return data
  }

  @Action({ rawError: true })
  public async intializeWcClass (region: string) {
    try {
      const wcClasses = await store.$apiClient.wcClasses.list(region)
      return wcClasses.data
    } catch (e: any) {
      this.setWcClassError('Failed to get class of business list')
    }
  }
  // #endregion
}
