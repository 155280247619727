export const enum BinderMutations {
  SET_PRE_APPLICATION = 'set_pre_application',
  SET_APPLICATION = 'set_application',
  SET_LAYOUT = 'set_layout',
  SET_VISIBLE_SECTIONS = 'set_visible_sections',
  INITIALIZE_SECTIONS = 'initialize_sections',
  ADD_ANSWER = 'add_answer',
  RESET_ANSWER = 'reset_answer',
  ERROR = 'error',
  ADD_VALIDATION = 'add_validation',
  REMOVE_VALIDATION = 'remove_validation',
  UPDATE_FORM_VALIDATION = 'update_form_validation',
  SET_ACTIVE_CONTROL = 'set_active_control',
  SET_TOTAL_SECTIONS = 'set_total_sections',
  SET_PENDING_QUOTE_CHECKOUT = 'set_pending_quote_checkout',
  SET_TOKEN = 'set_token',
  TOGGLE_BUSINESS_INFO = 'toggle_business_info',
  TOGGLE_SAVE_FOR_LATER = 'toggle_save_for_later',
  TOGGLE_SHARE_QUOTE = 'toggle_share_quote',
  TOGGLE_EDIT_QUESTION = 'toggle_edit_question',
  ADD_LOCATION = 'add_location',
  REMOVE_LOCATION = 'remove_location',
  SAVE_LOCATION_GEOMETRY = 'save_location_geometry',
  TOGGLE_CART = 'toggle_cart',
  TOGGLE_DRAWER = 'toggle_drawer',
  TOGGLE_HINT = 'toggle_hint',
  SET_PREAPPLICATION_COMPLETE = 'set_preapplication_complete',
  SET_PREAPPLICATION_NOAPPETITE = 'set_preapplication_noappetite',
  SET_PREFILLED_ANSWERS = 'set_prefilled_answers',
  TOGGLE_REVIEW_OVERLAY = 'toggle_review_overlay',
  SET_DRAWER_STATE = 'set_drawer_state',
  UNDO_ANSWERS = 'undo_answers',
  TOGGLE_QUOTES_STATUS_DRAWER = 'toggle_quotes_status_drawer',
}
