import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { BrokerageMutations as Mutations } from './mutation-types'
import store from '@/store'
import { BrokerageProfile } from '@/models/brokerage'
import { formatPhone } from '@/helpers/formatters'

@Module({ dynamic: true, store, name: 'BrokerageModule' })
export default class BrokerageModule extends VuexModule {
  public brokerage: BrokerageProfile | null = null

  get brokerageName () {
    return this.brokerage?.name ?? null
  }

  get brokerageEmail () {
    const contacts = this.brokerage?.contacts?.filter(contact => contact.type === 'primary') ?? []
    return contacts.length > 0
      ? contacts[0].email
      : null
  }

  get brokeragePhone () {
    const contacts = this.brokerage?.contacts ?? []
    return contacts.length > 0
      ? formatPhone(contacts[0].phone)
      : null
  }

  get brandColor () {
    return this.brokerage?.brandColor ?? '#1578B2'
  }

  get brokerageLogoUrl () {
    return this.brokerage?.logoUrl ?? ''
  }

  @Mutation
  private [Mutations.SET_BROKERAGE] (brokerage: BrokerageProfile): void {
    this.brokerage = brokerage
  }

  @Action({ commit: Mutations.SET_BROKERAGE })
  public setBrokerage (brokerage: BrokerageProfile): BrokerageProfile {
    return brokerage
  }

  @Action
  public reset () {
    this.context.commit(Mutations.SET_BROKERAGE, null)
  }
}
