import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { COBMutations } from '@/store/cob/mutation-types'
import store from '@/store'
import { COB } from '@/models/cob'

@Module({ dynamic: true, store, name: 'COBModule' })
export default class COBModule extends VuexModule {
  public cobs: COB[] | null = null
  public error: string | null = null

  // #region Mutations
  @Mutation
  private [COBMutations.GET_COB_LIST] (data: COB[]): void {
    this.cobs = data
  }

  @Mutation
  private [COBMutations.SET_COB_ERROR] (data: string): void {
    this.error = data
  }
  // #endregion

  // #region Actions
  @Action({ commit: COBMutations.GET_COB_LIST })
  public setCOBList (data: COB[]): COB[] {
    return data
  }

  @Action({ commit: COBMutations.SET_COB_ERROR })
  public setCOBError (data: string): string {
    return data
  }

  @Action({ rawError: true })
  public async initializeCOB (productTypeSlug?: string) {
    try {
      const classOfBusinesses = await store.$apiClient.classOfBusinesses.list(
        productTypeSlug
      )
      if (classOfBusinesses.data.length === 0) {
        const classOfBusinessesWithOutBrokerageIdFilter =
          await store.$apiClient.classOfBusinesses.list(
            productTypeSlug,
            undefined,
            true,
            true
          )
        this.setCOBList(classOfBusinessesWithOutBrokerageIdFilter.data)
      } else {
        this.setCOBList(classOfBusinesses.data)
      }
    } catch (e: any) {
      this.setCOBError('Failed to get class of business list')
    }
  }
  // #endregion
}
