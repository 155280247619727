export const enum QuoteMutations {
  SET_QUOTES = 'set_quotes',
  SET_PRODUCT_LIST = 'set_product_list',
  SET_PRODUCT_TYPE_LIST = 'set_product_type_list',
  SET_ACKNOWLEDGEMENTS = 'set_acknowledgments',
  SET_POLICIES = 'set_policies',
  UPDATE_QUOTE_SELECTIONS = 'update_quote_selections',
  SET_COVERAGES = 'set_coverages',
  UPDATE_COVERAGE_SELECTION = 'update_coverage_selection',
  SET_PENDING_UPDATES = 'set_pending_updates',
  SET_CARRIERS = 'set_carriers',
  SET_POLICY_COVERAGES = 'set_policy_coverages',
  UPDATE_CARRIER_SELECTION = 'update_carrier_selection',
  UPDATE_POLICY_COVERAGE_SELECTION = 'update_policy_coverage_selection',
  APPLY_FILTER = 'apply_filter',
  UPDATE_OCCURRENCE_LIMIT = 'update_occurrence_limit',
  UPDATE_DEDUCTIBLE = 'update_deductible',
  UPDATE_COVERAGE = 'update_coverage',
  UPDATE_SORT = 'update_sort',
  SET_COVERAGE_OPTIONS = 'set_coverage_options',
  GET_QUOTE_COVERAGES = 'get_quote_coverages',
  SET_CARRIER_OPTIONS = 'set_carrier_options',
  SET_QUOTE_PRODUCTS_INFO = 'set_quote_products_info',
  SET_QUOTE_STATUS_QUEUED = 'set_quote_status_queued',
  SET_QUOTE_PRODUCT_FILTER = 'set_quote_product_filter',
  CLEAR_QUOTE_COVERAGES = 'clear_quote_coverages'
}
