import { NavigationGuard } from 'vue-router'
import { ROUTES } from '../routes'
import { binderModule } from '@/store/store-accessor'
import { Progress } from '@/models/link'
import { errorLogger, warnLogger } from './commons'
import { parseValues } from '@/router/middleware/url/values-parser'
import { BRIZA_APPLICATION_TOKEN } from '@/store/binder-module'

/**
 * The url is prefixed with "/resume"
 * @param url request URL.
 */
export function defaultUrlMatcherFn (url: string): boolean {
  return url.startsWith('/resume')
}

/**
 * Resumption middleware.
 * Resume application session via URL token.
 * @param resumePath
 * @param logger Error logger.
 */
export const resumptionMiddleware = (
  logger: { error: errorLogger, warn: warnLogger },
  urlMatcherFn: (url: string) => boolean = defaultUrlMatcherFn
): NavigationGuard => {
  return (to, from, next) => {
    // Irrelevant path
    if (!urlMatcherFn(to.path)) {
      return next()
    }

    const hash = to.hash.replace('#', '')
    const [token, prefills] = hash.split('?')
    if (prefills) {
      const answers = parseValues(`#${prefills}`)
      binderModule.setPreFilledAnswers(answers)
    }
    if (token) {
      sessionStorage.setItem(BRIZA_APPLICATION_TOKEN, token)
      binderModule.setToken(token)
      binderModule.resumeApplication()
        .then((application) => {
          // Restore pre-application
          switch (application.progress) {
            case Progress.preApplication:
              next({
                name: ROUTES.PRE_APPLICATION,
                params: { id: application.id }
              })
              break
            case Progress.application:
              next({
                name: ROUTES.APPLICATION,
                params: { id: application.id }
              })
              break
            case Progress.review:
              next({
                name: ROUTES.REVIEW,
                params: { id: application.id }
              })
              break
            case Progress.quotes:
              next({
                name: ROUTES.QUOTES,
                params: { id: application.id }
              })
              break
            case Progress.payment:
              next({
                name: ROUTES.CHECKOUT,
                params: { id: application.id }
              })
              break
            default:
              next({ name: ROUTES.PAGE_NOT_FOUND })
          }
        })
        .catch((error: Error) => {
          logger.warn(error.message, {
            stack: error.stack
          })
          next({ name: ROUTES.EXPIRED })
        })
    } else {
      next({ name: ROUTES.PAGE_NOT_FOUND })
    }
  }
}
