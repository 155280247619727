







































import { computed, defineComponent } from '@vue/composition-api'
import LoadingIcon from '@/components/loading-icon/index.vue'
import { CheckIcon } from 'vue-feather-icons'

export default defineComponent({
  name: 'FeedbackFooterActions',
  components: {
    LoadingIcon, CheckIcon
  },
  props: {
    submission: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
    submissionResult: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['submit'],
  setup (props) {
    const hasRatingSelected = computed(() => {
      return props.submission?.ratingReasons?.length
    })
    return { hasRatingSelected }
  }
})
