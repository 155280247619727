import { isObject } from '@/helpers/utils'

export enum UnderwriterSlug {
  Hiscox = 'hiscox',
  Markel = 'markel',
  Cna = 'cna',
  Employers = 'employers',
  LibertyMutual = 'liberty-mutual',
  Acuity = 'acuity',
  Coalition = 'coalition',
  Coterie = 'coterie',
  AmTrust = 'amtrust',
  Guard = 'guard',
  Homesite = 'homesite',
}

/**
 * This enum is now considered as the main source of truth,
 * regarding the supported ans valid product types.
 */
export enum ProductTypeSlug {
  BusinessOwnersPolicy = 'bop',
  ErrorsAndOmissions = 'eo',
  GeneralLiability = 'gl',
  WorkersCompensation = 'wc',
}

/**
 * A collection of questions including their layout.
 * This is the same structure as described in `models/questions/schema.yaml`.
 *
 * The main source of truth for this document is:
 * https://github.com/briza-insurance/briza-api/blob/master/src/models/questions/readme.md
 */
export interface Questionnaire {
  version: number
  /**
   * Composite tree structure, defining the desired layout composition,
   * and providing additional meta information for the given section.
   */
  layout: LayoutSection[]
  /**
   * Flat array of all questions in the Questionnaire.
   */
  questions: Question[]
}

export function isLayoutSection (section: SectionItem): section is LayoutSection {
  return (
    isObject(section) &&
    ['ui', 'meta', 'items'].every(
      (key) => key in Object(section)
    )
  )
}

/**
 * Layout section.
 * Represents an UI block which holds question references or
 * sub-sections.
 */
export interface LayoutSection {
  ui: SectionUI
  meta: SectionMeta
  items: SectionItem[]
}

/**
 * Section item: Question ID or Section
 */
export type SectionItem = string | LayoutSection

/**
 * Collection of Section UI related properties.
 */
export interface SectionUI {
  title: string
  description?: string
}

export enum SectionMetaLabel {
  WxcClassQuestions = 'WcClassQuestions',
  IncludedCoverages = 'IncludedCoverages',
  OptionalCoverages = 'OptionalCoverages',
  Deductibles = 'Deductibles',
  BuildingLimits = 'BuildingLimits',
  PropertyLimits = 'PropertyLimits',
}

/**
 * Collection of Section meta properties.
 */
export interface SectionMeta {
  /**
   * Defines the meaning of this section i.e. if it represents a section or a grouping of questions.
   */
  composition: SectionMetaComposition
  features?: SectionMetaFeatures
  /**
   * Allows the front-end to treat the group in a special way
   */
  label?: SectionMetaLabel
}

/**
 * The meaning of this section i.e. if it represents a section or a grouping of questions.
 */
export enum SectionMetaComposition {
  /**
   * A grouping of questions within the current section
   */
  Group = 'group',

  /**
   * A section is a collection of sections or groups
   */
  Section = 'section',
}

export interface SectionMetaFeatures {
  sum: string
}

/**
 * Questionnaire question.
 */
export interface Question {
  id: string
  /**
   * Type defines the shape of the data expected to be
   * provided by the answer.
   */
  type: QuestionType
  /**
   * Options given to user to choose from for the given question.
   * Applicable only for question types: Select, Multi Select.
   */
  options?: QuestionOption[]
  /**
   * ISO 4217 currency code identifier
   * (https://www.iso.org/iso-4217-currency-codes.html - Alphabetic Code)
   * associated with input, e.g. CAD.
   * Applicable only for question type: Currency.
   */
  currency?: string
  /**
   * The actual question. It supports RICHTEXT.
   */
  question: string
  /**
   * The actual question text if part of group. It supports RICHTEXT.
   */
  questionWithinGroup?: string
  /**
   * Unified string that can be used for question text if in group, or short label for print
   * or short label for coverages on quote screen
   */
  shortQuestion?: string
  /**
   * Default answer value for the given question.
   */
  default?: AnswerValue
  /**
   * UI metadata for the given question.
   */
  ui: QuestionUI
  /**
   * Required flag.
   */
  required: boolean
  /**
   * Sequence of validation rules which should be applied on
   * the answer value to determine the validity. Format: wegood.
   */
  validation?: QuestionValidationRule[]
  /**
   * Condition under which the question is being rendered.
   * Format: Illogical logical or comparison expression.
   */
  condition?: Condition
  /**
   * A question flagged with this property (existing and true value),
   * is not being part of the pre-application or application, rather
   * it is being used in the pre-checkout phase, after the user selects
   * the quote/s, as an exclusion or an acknowledgement.
   */
  exclusion?: boolean
}

/**
 * Currently supported question types
 */
export enum QuestionType {
  SingleLine = 'SingleLine',
  MultiLine = 'MultiLine',
  Number = 'Number',
  Decimal = 'Decimal',
  Currency = 'Currency',
  Select = 'Select',
  MultiSelect = 'MultiSelect',
  Date = 'Date',
  DateYear = 'DateYear',
  Boolean = 'Boolean',
  Address = 'Address',
  Void = 'Void',
  ClassOfBusiness = 'ClassOfBusiness',
  WcClassCode = 'WcClassCode',
  ProductType = 'ProductType',
}

export interface QuestionOption {
  /**
   * UI label.
   */
  label: string
  /**
   * Unique option key among the rest of the options.
   */
  key: string | number | boolean
}

/**
 * Question Validation Rule type aligned with
 * the scheme and wegood library.
 */
export enum QuestionValidationRuleType {
  Present = 'present',
  Equal = 'equal',
  Pattern = 'pattern',
  Range = 'range',
  Length = 'length',
  Exclude = 'exclude',
  Include = 'include',
  Date = 'date',
  Year = 'year',
}

/**
 * Question Validation Rule base.
 */
export interface QuestionValidationRule {
  type: QuestionValidationRuleType
  // Error message return in the case when this rule
  // is evaluated as invalid. If not set, the default
  // validation error message is used.
  errorMessage?: string

  // TODO: the following fields are temporary until we incorporate the new validation logic into binder
  // See https://github.com/briza-insurance/briza-binder/issues/206
  min: number | undefined | null
  max: number | undefined | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  pattern?: string
  exclusions?: Array<string | number>
  inclusions?: Array<string | number>
  start?: string | number
  end?: string | number
  // END TODO: end temporary field
}

/**
 * Validation Rule Equal.
 * Compare the value to a specific value.
 */
export interface QuestionValidationRuleEqual extends QuestionValidationRule {
  type: QuestionValidationRuleType.Equal
  value: string | number | boolean
}

/**
 * Validation Rule Range.
 * The value must be in the range (inclusive).
 * If one of the min, max is not set, than the rule is used
 * as Min or Max value. Applicable for numbers only.
 */
export interface QuestionValidationRuleRange extends QuestionValidationRule {
  type: QuestionValidationRuleType.Range
  min: number | undefined | null
  max: number | undefined | null
}

/**
 * Validation Rule Length.
 * The value must be in the given string length range (inclusive).
 * If one of the min, max is not set, than the rule is used as
 * Min or Max value.
 */
export interface QuestionValidationRuleLength extends QuestionValidationRule {
  type: QuestionValidationRuleType.Length
  min: number | undefined | null
  max: number | undefined | null
}

/**
 * Validation Rule Pattern.
 * Custom regular expression, applicable for strings only.
 *
 * IMPORTANT: The pattern is represented as string therefore it cannot
 * contain the leading and ending "/" character.
 src/interfaces/Questionnaire.ts
 */
export interface QuestionValidationRulePattern extends QuestionValidationRule {
  type: QuestionValidationRuleType.Pattern
  pattern: string
}

/**
 * Validation Rule Date.
 * Date range validation. If the start or end is not defined,
 * on restriction is applied on start or end, still at least
 * one of them must be defined.
 *
 * Date Validation Rule Annotation:
 * Annotation     Meaning                                   Sample
 * ===================================================================
 * 0           |  Today.
 * -1          |  In past.
 * 1           |  In future
 * -Nd         |  N days in past, relative from today.   |  -10d
 * Nd          |  N days in past, relative from today.   |  10d
 * -Nw         |  N weeks in past, relative from today.  |  -2w
 * Nw          |  N weeks in past, relative from today.  |  2w
 * -Nm         |  N months in past, relative from today. |  -6m
 * Nm          |  N months in past, relative from today. |  6m
 * -Ny         |  N years in past, relative from today.  |  -2y
 * Ny          |  N years in past, relative from today.  |  2y
 * yyyy-mm-dd  |  Specific date, ISO date string.        |  2019-12-31
 */
export interface QuestionValidationRuleDate extends QuestionValidationRule {
  type: QuestionValidationRuleType.Date
  start?: string | number
  end?: string | number
}

/**
 * Validation Rule Exclude.
 * The value must not be contained in the exclusions, applicable
 * only for string or number value.
 */
export interface QuestionValidationRuleExclude extends QuestionValidationRule {
  type: QuestionValidationRuleType.Exclude
  exclusions: Array<string | number>
}

/**
 * Validation Rule Include.
 * The value must be contained in the inclusions, applicable only
 * for string or number value.
 */
export interface QuestionValidationRuleInclude extends QuestionValidationRule {
  type: QuestionValidationRuleType.Include
  inclusions: Array<string | number>
}

/**
 * Collection question UI properties.
 */
export interface QuestionUI {
  /**
   * Input label. It supports RICHTEXT.
   */
  label?: string
  /**
   * Input description. It supports RICHTEXT.
   */
  description?: string
  /**
   * Input placeholder.
   */
  placeholder?: string
  /**
   * Input mask.
   */
  mask?: string
  /**
   * The control defines the render flavor, i.e. how the given
   * question type should be rendered, e.g. select -> dropdown.
   */
  control?: QuestionControlType
  /**
   * Input hint, An array of hint text blocks. It supports RICHTEXT.
   */
  hint?: string[]
  /**
   * Validation messages for the given validation states.
   * validation.required and validation[validation_rule_key]
   */
  validation?: QuestionUIValidation
}

/**
 * Validation messages mapping object.
 * A key represent the validation rule type,
 * value is the actual validation message.
 */
export interface QuestionUIValidation {
  present?: string
  required?: string
  range?: string
  pattern?: string
  equal?: string
  length?: string
  exclude?: string
  include?: string
  date?: string
  year?: string
}

/**
 * The control defines the render flavor of
 * a given question type.
 */
export enum QuestionControlType {
  // Not-applicable / default
  NA = '',
  // Select / Multi-Select
  Dropdown = 'dropdown', // Default
  Chips = 'chips',
  // Select
  Toggle = 'toggle',
  Checkbox = 'checkbox',
}

export type Condition = [string, any, any] // eslint-disable-line @typescript-eslint/no-explicit-any

/**
 * An entry in the subject file - index of files to merge
 * TODO: this needs a better name
 */
export interface Subject {
  underwriter: UnderwriterSlug
  product: ProductTypeSlug
  data?: Questionnaire
}

/**
 * Answer value for questions for type "Address"
 */
export interface Address {
  [key: string]: string | undefined // index signature
  street: string
  secondary?: string
  city: string
  region?: string
  postalCode: string
  county?: string
  country: string
}

export interface AnswerValueWcClass {
  /** Object Index Signature to access object properties by key. */
  [key: string]: string
  code: string
  industry: string
}

/**
 * Map of question IDs to answer values
 */
// @ts-ignore
export type AnswerMap = {
  [id: string]: AnswerValue
}

/**
 * Answers to questions
 */
export type AnswerValue =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | (string | number | boolean)[]
  | Address
  | AnswerValueWcClass
  | null
