import { render, staticRenderFns } from "./launcher.vue?vue&type=template&id=3f0835ba&scoped=true&"
import script from "./launcher.vue?vue&type=script&lang=ts&"
export * from "./launcher.vue?vue&type=script&lang=ts&"
import style0 from "./launcher.vue?vue&type=style&index=0&id=3f0835ba&prod&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0835ba",
  null
  
)

export default component.exports