import { Product, ProductNames } from '@/models/product'
import { ProductTypeSlug } from '@/models/questionnaire'

export function getProductName (slug?: string) {
  switch (slug) {
    case ProductTypeSlug.BusinessOwnersPolicy:
      return ProductNames.BusinessOwnersPolicy
    case ProductTypeSlug.GeneralLiability:
      return ProductNames.GeneralLiability
    case ProductTypeSlug.ErrorsAndOmissions:
      return ProductNames.ErrorsAndOmissions
    case ProductTypeSlug.WorkersCompensation:
      return ProductNames.WorkersCompensation
    default:
      return 'Selected Product'
  }
}

export function findProductById (productList: Product[], productId: string) {
  return productList.find((product) => product.id === productId)
}
