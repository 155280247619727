






























































import {
  defineComponent,
  computed,
  nextTick,
  onMounted,
  onBeforeUnmount,
  ref
} from '@vue/composition-api'
import LoadingIcon from '@/components/loading-icon/index.vue'
import { ROUTES } from '@/router/routes'
import { brokerageModule, binderModule } from '@/store/store-accessor'

import useLoadable from '@/use/useLoadable'

export default defineComponent({
  components: {
    LoadingIcon
  },
  setup (props, { root }) {
    const router = root.$options.router
    const route = root.$options?.router?.currentRoute || null

    const { showBtnLoading, loading, btnLoading } = useLoadable()
    const windowHeight = ref(0)

    const beginPreApplication = async () => {
      showBtnLoading()
      const preApp = await binderModule.createPreApplication()
      if (router) {
        router.push({
          name: ROUTES.PRE_APPLICATION,
          params: { id: preApp?.id || '' }
        })
      }
    }
    const brokerageName = computed(() => {
      return brokerageModule.brokerageName
    })

    const greeting = computed(() => {
      const AFTERNOON_START = 12
      const EVENING_START = 17
      const currentHour = new Date().getHours()
      if (currentHour >= AFTERNOON_START && currentHour <= EVENING_START) {
        return 'Good afternoon!'
      } else if (currentHour >= EVENING_START) {
        return 'Good evening!'
      } else {
        return 'Good morning!'
      }
    })

    const onResize = () => {
      windowHeight.value = window.innerHeight
    }

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    onMounted(() => {
      document.title = brokerageModule.brokerageName
        ? `${brokerageModule.brokerageName} | ${route?.meta?.title}`
        : route?.meta?.title

      windowHeight.value = window.innerHeight

      nextTick(() => {
        window.addEventListener('resize', onResize)
      })
    })

    return {
      beginPreApplication,
      greeting,
      windowHeight,
      btnLoading,
      brokerageName,
      loading
    }
  }
})
