export interface Underwriter {
  id: string
  name: string
  slug: UnderwriterSlug
  companyCode: string
  createdAt: string
  updatedAt: string
}

export enum BillingType {
  Brokerage = 'brokerage',
  Direct = 'direct',
}

export const enum UnderwriterSlug {
  Hiscox = 'hiscox',
  Markel = 'markel',
  Employers = 'employers',
  Cna = 'cna',
  Coalition = 'coalition',
  Coterie = 'coterie',
  BtisAmTrust = 'btis-amtrust',
  BtisClearspring = 'btis-clearspring',
  BtisCna = 'btis-cna',
  Homesite = 'homesite',
}
