import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'
import { CartItem } from '@/models/cart'
import { CartMutations } from '@/store/cart-module/mutation-types'
import { findQuoteById } from '@/helpers/quote'
import { quoteModule } from '@/store/store-accessor'
import { findProductById } from '@/helpers/products'
import { BillingType } from '@/models/underwriter'

@Module({ dynamic: true, store, name: 'CartModule' })
export default class CartModule extends VuexModule {
  public cartItems: CartItem[] = []
  public pendingUpdateQuote: string | null = null

  /**
   * Totals collected by agent after checkout
   * - must get quote info -> quote product -> quote billing and collection info
   * - if (billingType === 'direct' && collectPayment === false)
   * - then add it to totals which will be subtracted from policy totals to arrive
   * - at checkout grand total
   */
  get collectedByAgent (): number {
    return this.cartItems.filter(cartItem => {
      const quoteInfo = findQuoteById(quoteModule.quotesData, cartItem.quoteId)
      if (quoteInfo) {
        const productInfo = findProductById(quoteModule.productList, quoteInfo.productId)
        if (productInfo) {
          return quoteInfo?.billingType === BillingType.Direct && !quoteInfo.collectPayment
        }
      }
      return false
    }).reduce((total, cartItem) => (quoteModule.quoteSelections
      .find(quote => quote.quoteId === cartItem.quoteId)?.downpayment ?? 0) + total, 0)
  }

  get isQuoteInCart () {
    return (quoteId: string): boolean => {
      return this.cartItems.find(cartItem => cartItem.quoteId === quoteId) !== undefined
    }
  }

  @Mutation
  private [CartMutations.ADD_ITEM_TO_CART] (data: CartItem): void {
    const cartClone = this.cartItems.filter(cartItem => cartItem.slug !== data.slug)
    this.cartItems = [
      ...cartClone,
      data
    ]
  }

  @Mutation
  private [CartMutations.REMOVE_ITEM_FROM_CART] (quoteId: string): void {
    this.cartItems = this.cartItems.filter(cartItem => cartItem.quoteId !== quoteId)
  }

  @Mutation
  private [CartMutations.SET_PENDING_UPDATE_QUOTE] (quoteId: string): void {
    this.pendingUpdateQuote = quoteId
  }

  @Mutation
  private [CartMutations.CLEAR_CART] (data: CartItem[]): void {
    this.cartItems = data
  }

  @Action({ commit: CartMutations.ADD_ITEM_TO_CART })
  public addItem (data: CartItem): CartItem {
    return data
  }

  @Action({ commit: CartMutations.REMOVE_ITEM_FROM_CART })
  public removeItem (quoteId: string): string {
    return quoteId
  }

  @Action({ commit: CartMutations.SET_PENDING_UPDATE_QUOTE })
  public setPendingUpdateQuote (quoteId: string | null) {
    return quoteId
  }

  @Action({ commit: CartMutations.CLEAR_CART })
  public clearCart () {
    return []
  }
}
