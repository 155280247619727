













































import { defineComponent, onMounted } from '@vue/composition-api'
import useBrokerage from '@/use/useBrokerage'
import { ROUTES } from '@/router/routes'

export default defineComponent({
  name: 'NotFound',
  setup (props, { root }) {
    const { brokerageName, brokerageEmail, brokeragePhone } = useBrokerage()
    const router = root.$options.router
    const route = root.$options?.router?.currentRoute || null
    onMounted(() => {
      document.title = brokerageName.value
        ? `${brokerageName.value} | ${route?.meta?.title}`
        : route?.meta?.title
    })
    const goHome = () => {
      if (router) {
        router.push({
          name: ROUTES.HOME
        })
      }
    }
    return {
      brokerageName,
      brokerageEmail,
      brokeragePhone,
      goHome
    }
  }
})
