import { engine } from '@/engine'
import { advanceVisibleSections } from '@/helpers/layout'
import {
  getAnswersByQuestionId,
  getQuestionIdsByType,
  removeAddress
} from '@/helpers/questions'
import { GeometryInfo } from '@/models/address'
import { Application } from '@/models/application'
import {
  PreApplicationErrors,
  ApplicationErrors,
  TokenNotFoundError
} from '@/models/errors'
import { Layout, Section } from '@/models/layout'
import { Link, Progress } from '@/models/link'
import { PreApplication, PreApplicationStatus } from '@/models/pre-application'
import { AnswerMap, Question, Questionnaire } from '@/models/questionnaire'
import { ValidationMessage } from '@/models/validation-messages'
import store from '@/store'
import moment from 'moment-timezone'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { BinderMutations } from './mutation-types'
import { parseValue } from './parse-value'
import { brokerageModule } from '@/store/store-accessor'

const initialAnswers = {
  NumberOfLocations: 1
}

export const BRIZA_APPLICATION_TOKEN = 'briza-application-token'

@Module({ dynamic: true, store, name: 'BinderModule' })
export default class BinderModule extends VuexModule {
  public preApplication: PreApplication | null = null
  public application: Application | null = null
  public layout: Section[] = []
  public visibleSections: string[] = []
  public answers: AnswerMap = initialAnswers
  public preAppError = ''
  public validationMessages: ValidationMessage[] = []
  public totalSections = 0
  public pendingQuoteAtCheckout: string | null = null
  private token = ''
  public toggleEditQuestion = ''
  public toggleBusinessInfo = false
  public toggleSaveForLater = false
  public toggleShareQuote = false
  public toggleReviewOverlay = false
  public toggleQuotesStatusDrawer = false
  public toggleCart = false
  public toggleHint = false
  public isDrawerOpen = false
  public locationGeometry: GeometryInfo[] = []
  public preApplicationComplete = false
  public preApplicationNoAppetite = false
  public preFilledAnswers: Record<string, unknown> = {}

  // Active Control will be used to determine which control is currently in focus, thus displaying the hint for it
  public activeControl = ''

  // #region Mutations

  get tokenData () {
    return this.token
  }

  get questions (): Question[] {
    if (this.application) {
      return this.application.questionnaire.questions
    }
    if (this.preApplication) {
      return this.preApplication.questionnaire?.questions
    }
    return []
  }

  get visibleQuestions (): Question[] {
    return this.questions.filter((question) =>
      question.condition && question.id !== 'NumberOfLocations'
        ? engine.evaluate(question.condition, this.answers)
        : true
    )
  }

  get answersToSave (): AnswerMap {
    return this.visibleQuestions
      .map((question) => question.id)
      .filter((key) => key in this.answers)
      .reduce(
        (filteredAnswers, key) => ({
          ...filteredAnswers,
          [key]: this.answers[key]
        }),
        {}
      )
  }

  @Mutation
  private [BinderMutations.SET_PRE_APPLICATION] (data: PreApplication): void {
    this.preApplication = data
  }

  @Mutation
  private [BinderMutations.SET_APPLICATION] (data: Application): void {
    this.application = data
  }

  @Mutation
  private [BinderMutations.SET_LAYOUT] (data: Section[]): void {
    this.layout = data
  }

  @Mutation
  private [BinderMutations.ERROR] (error: string): void {
    this.preAppError = error
  }

  @Mutation
  private [BinderMutations.SET_VISIBLE_SECTIONS] (data: string): void {
    this.visibleSections.push(data)
  }

  @Mutation
  private [BinderMutations.INITIALIZE_SECTIONS] (data: string[]): void {
    this.visibleSections = data
  }

  @Mutation
  private [BinderMutations.ADD_ANSWER] (data: object): void {
    this.answers = {
      ...this.answers,
      ...data
    }
  }

  @Mutation
  private [BinderMutations.RESET_ANSWER] (data: object): void {
    this.answers = {
      ...initialAnswers,
      ...data
    }
  }

  @Mutation
  private [BinderMutations.UNDO_ANSWERS] (data: {
    answers: AnswerMap
    validationMessages: ValidationMessage[]
  }): void {
    this.answers = data.answers
    this.validationMessages = data.validationMessages
  }

  @Mutation
  private [BinderMutations.ADD_VALIDATION] (data: ValidationMessage): void {
    // remove previous messages for same question before appending
    const question = this.validationMessages.find(
      (message) => message.field === data.field
    )
    question
      ? (question.message = data.message)
      : this.validationMessages.push(data)
  }

  @Mutation
  private [BinderMutations.REMOVE_VALIDATION] (field: string): void {
    this.validationMessages = this.validationMessages.filter(
      (message) => message.field !== field
    )
  }

  @Mutation
  private [BinderMutations.UPDATE_FORM_VALIDATION] (
    data: ValidationMessage[]
  ): void {
    this.validationMessages = data
  }

  @Mutation
  private [BinderMutations.SET_ACTIVE_CONTROL] (data: string): void {
    this.activeControl = data
  }

  @Mutation
  private [BinderMutations.SET_TOTAL_SECTIONS] (data: number): void {
    this.totalSections = data
  }

  @Mutation
  private [BinderMutations.SET_PENDING_QUOTE_CHECKOUT] (id: string): void {
    this.pendingQuoteAtCheckout = id
  }

  @Mutation
  private [BinderMutations.SET_TOKEN] (data: string): void {
    this.token = data
  }

  @Mutation
  private [BinderMutations.TOGGLE_BUSINESS_INFO] (data: boolean): void {
    this.toggleBusinessInfo = data
    this.toggleEditQuestion = ''
    this.toggleSaveForLater = false
    this.toggleShareQuote = false
    this.toggleReviewOverlay = false
    this.toggleQuotesStatusDrawer = false
    this.toggleCart = false
    this.toggleHint = false
  }

  @Mutation
  private [BinderMutations.TOGGLE_SAVE_FOR_LATER] (data: boolean): void {
    this.toggleSaveForLater = data
    this.toggleEditQuestion = ''
    this.toggleBusinessInfo = false
    this.toggleShareQuote = false
    this.toggleReviewOverlay = false
    this.toggleQuotesStatusDrawer = false
    this.toggleCart = false
    this.toggleHint = false
  }

  @Mutation
  private [BinderMutations.TOGGLE_SHARE_QUOTE] (data: boolean): void {
    this.toggleShareQuote = data
    this.toggleReviewOverlay = false
    this.toggleQuotesStatusDrawer = false
    this.toggleEditQuestion = ''
    this.toggleSaveForLater = false
    this.toggleBusinessInfo = false
    this.toggleCart = false
    this.toggleHint = false
  }

  @Mutation
  private [BinderMutations.TOGGLE_REVIEW_OVERLAY] (data: boolean): void {
    this.toggleShareQuote = false
    this.toggleReviewOverlay = data
    this.toggleQuotesStatusDrawer = false
    this.toggleEditQuestion = ''
    this.toggleSaveForLater = false
    this.toggleBusinessInfo = false
    this.toggleCart = false
    this.toggleHint = false
  }

  @Mutation
  private [BinderMutations.TOGGLE_EDIT_QUESTION] (data: string): void {
    this.toggleEditQuestion = data
    this.toggleShareQuote = false
    this.toggleSaveForLater = false
    this.toggleBusinessInfo = false
    this.toggleQuotesStatusDrawer = false
    this.toggleCart = false
    this.toggleHint = false
  }

  @Mutation [BinderMutations.ADD_LOCATION] (data: number): void {
    this.answers.NumberOfLocations = data
  }

  @Mutation [BinderMutations.REMOVE_LOCATION] (data: number): void {
    this.answers.NumberOfLocations = data
  }

  @Mutation [BinderMutations.SAVE_LOCATION_GEOMETRY] (data: GeometryInfo): void {
    this.locationGeometry.push(data)
  }

  @Mutation [BinderMutations.TOGGLE_CART] (data: boolean): void {
    this.toggleCart = data
    this.toggleEditQuestion = ''
    this.toggleSaveForLater = false
    this.toggleShareQuote = false
    this.toggleReviewOverlay = false
    this.toggleQuotesStatusDrawer = false
    this.toggleBusinessInfo = false
    this.toggleHint = false
  }

  @Mutation [BinderMutations.TOGGLE_QUOTES_STATUS_DRAWER] (data: boolean): void {
    this.toggleHint = false
    this.toggleEditQuestion = ''
    this.toggleCart = false
    this.toggleSaveForLater = false
    this.toggleShareQuote = false
    this.toggleReviewOverlay = false
    this.toggleQuotesStatusDrawer = data
    this.toggleBusinessInfo = false
  }

  @Mutation [BinderMutations.TOGGLE_HINT] (data: boolean): void {
    this.toggleHint = data
    this.toggleEditQuestion = ''
    this.toggleCart = false
    this.toggleSaveForLater = false
    this.toggleShareQuote = false
    this.toggleReviewOverlay = false
    this.toggleQuotesStatusDrawer = false
    this.toggleBusinessInfo = false
  }

  @Mutation [BinderMutations.TOGGLE_DRAWER] (data: boolean): void {
    this.toggleCart = data
    this.toggleEditQuestion = ''
    this.toggleSaveForLater = data
    this.toggleShareQuote = data
    this.toggleBusinessInfo = data
    this.toggleHint = data
  }

  @Mutation [BinderMutations.SET_DRAWER_STATE] (data: boolean): void {
    this.isDrawerOpen = data
  }

  @Mutation [BinderMutations.SET_PREAPPLICATION_COMPLETE] (data: boolean): void {
    this.preApplicationComplete = data
  }

  @Mutation [BinderMutations.SET_PREAPPLICATION_NOAPPETITE] (
    data: boolean
  ): void {
    this.preApplicationNoAppetite = data
  }

  @Mutation [BinderMutations.SET_PREFILLED_ANSWERS] (
    answers: Record<string, unknown>
  ): void {
    this.preFilledAnswers = answers
  }
  // #endregion

  // #region Actions
  @Action({ commit: BinderMutations.ERROR })
  public setError (delta: string): string {
    return delta
  }

  @Action({ commit: BinderMutations.SET_PRE_APPLICATION })
  public setPreApplication (data?: PreApplication): PreApplication | undefined {
    // Hard code condition for NumberOfLocations questions to always evaluate to false since we will infer the number
    if (data) {
      return {
        ...data,
        questionnaire: {
          ...data.questionnaire,
          questions: data.questionnaire.questions.map((question) => {
            if (question.id === 'NumberOfLocations') {
              question.condition = ['==', true, false]
            }
            if (
              question.id === 'RequestedTypesOfInsurance' &&
              window.location.href.includes('appalachian')
            ) {
              question.options = [
                {
                  key: 'bop',
                  label: 'Business Owner’s Policy'
                }
              ]
            }
            return question
          })
        }
      }
    }
    return data
  }

  @Action({ commit: BinderMutations.SET_APPLICATION })
  public setApplication (data?: Application): Application | undefined {
    return data
  }

  @Action({ commit: BinderMutations.SET_LAYOUT })
  public setLayout (sections: Section[]): Section[] {
    return sections
  }

  @Action({ commit: BinderMutations.INITIALIZE_SECTIONS })
  public initializeSections (data: string[]): object {
    return data
  }

  @Action({ commit: BinderMutations.SET_VISIBLE_SECTIONS })
  public setVisibleSections (data: string): string {
    return data
  }

  @Action({ commit: BinderMutations.ADD_ANSWER })
  public addAnswer (data: object): object {
    return data
  }

  @Action({ commit: BinderMutations.RESET_ANSWER })
  public resetAnswer (): object {
    return {}
  }

  @Action({ commit: BinderMutations.UNDO_ANSWERS })
  public undoAnswers (data: object): object {
    return data
  }

  @Action({ commit: BinderMutations.SET_TOKEN })
  public async setToken (token?: string): Promise<string | undefined> {
    if (token) {
      await store.$apiClient.brokerageMetadata()
      brokerageModule.setBrokerage(store.$apiClient.authenticatedBrokerage)
    }
    return token
  }

  /**
   * Create an empty pre-app for the given API key and load it into state.
   */
  @Action({ rawError: true })
  public async createPreApplication (): Promise<PreApplication | undefined> {
    const timeZone = moment.tz.guess(true) || 'Etc/UTC'
    const [preApplication, token] =
      await store.$apiClient.preApplications.create(timeZone)

    // Throw error if pre-application does not exist
    if (!preApplication) {
      throw new Error('Illegal state - expected pre-application')
    }
    sessionStorage.setItem(BRIZA_APPLICATION_TOKEN, token)

    await Promise.all([this.setToken(token), this.resetAnswer()])

    return this.applyPreApplication(preApplication)
  }

  @Action({ commit: BinderMutations.SET_PREFILLED_ANSWERS })
  public async setAnswersFromPreFilled (
    target: PreApplication | Application
  ): Promise<AnswerMap> {
    const questionById = new Map<string, Question>(
      target.questionnaire.questions.map((question) => [question.id, question])
    )
    const keysToBePreFilled = Object.keys(this.preFilledAnswers).filter(
      (questionId) => questionById.has(questionId)
    )

    this.addAnswer(
      keysToBePreFilled.reduce(
        (answers, key) => ({
          ...answers,
          [key]: parseValue(questionById.get(key), this.preFilledAnswers[key])
        }),
        {}
      )
    )

    return Object.keys(this.preFilledAnswers)
      .filter((id) => !keysToBePreFilled.includes(id))
      .reduce(
        (answers, key) => ({ ...answers, [key]: this.preFilledAnswers[key] }),
        {}
      )
  }

  /**
   * Check that the state contains the pre application with the given guid, and reload if not
   */
  @Action({ rawError: true })
  public async checkPreApplication () {
    const token = await this.initToken()

    if (!token) {
      throw new Error('token not set for pre-application')
    }

    const [linkId] = token.split('.')
    const preApplications = await store.$apiClient.links.preApplications.list(
      linkId
    )
    const preApplication = preApplications.data[0]

    if (!preApplication) {
      // TODO:  What is the purpose of .setError?
      this.setError('NO QUESTION SET')
      this.reset()
      return
    }

    if (preApplication.status === PreApplicationStatus.Completed) {
      await this.setPreApplicationComplete()
    }

    if (preApplication.status === PreApplicationStatus.Eliminated) {
      await this.setPreApplicationNoAppetite()
    }

    return this.applyPreApplication(preApplication)
  }

  /**
   * Check that the state contains the pre application with the given guid, and reload if not
   */
  @Action({ rawError: true })
  public async checkApplication () {
    const token = await this.initToken()
    if (!token) throw new TokenNotFoundError('Token not found')

    const [linkId] = token.split('.')
    const applications = await store.$apiClient.links.applications.list(linkId)
    const application = applications.data[0]

    if (!application) {
      // TODO:  What is the purpose of .setError?
      this.setError('NO QUESTION SET')
      this.reset()
      return
    }

    return this.applyApplication(application)
  }

  @Action
  private initToken () {
    const token = sessionStorage.getItem(BRIZA_APPLICATION_TOKEN) ?? undefined
    return this.setToken(token)
  }

  @Action
  private async applyPreApplication (preApplication: PreApplication) {
    await Promise.all([
      this.setApplication(undefined),
      this.addAnswer(preApplication.answers)
    ])
    await this.setAnswersFromPreFilled(preApplication)
    return this.setPreApplication(preApplication)
  }

  @Action
  private async applyApplication (application: Application) {
    await this.setPreApplicationComplete()
    await this.setAnswersFromPreFilled(application)
    await this.addAnswer(application.answers)
    return this.setApplication(application)
  }

  @Action
  public reset () {
    return Promise.all([
      this.setPreApplication(undefined),
      this.setApplication(undefined)
    ])
  }

  @Action
  public setQuestionnaire (questionnaire: Questionnaire) {
    // sets layout and question set node from the api response and sets to individual objects
    const layout = new Layout(questionnaire.layout)
    const sections = layout.sections
    const availableSections = layout.availableSections(sections)

    // Sets the visible and hidden sections in the global state
    // this will be used to determine the number of sections visible on the screen

    return Promise.all([
      this.setLayout(sections),
      this.setTotalSections(availableSections.length),
      this.setInitialSectionData({
        availableSections,
        sections
      }),
      this.setActiveControl('')
    ])
  }

  @Action({ rawError: true })
  public async convertToApplication (): Promise<Application | undefined> {
    if (!this.preApplication) {
      throw new Error('Illegal state - pre app needed')
    }

    let application: Application | null = null
    try {
      application = await store.$apiClient.applications.create(
        this.preApplication.id
      )
    } catch (err: any) {
      if (
        err.code === PreApplicationErrors.NO_APPETITE ||
        err.code === PreApplicationErrors.NO_WORKERS_COMPENSATION_AVAILABILITY
      ) {
        this.setPreApplicationNoAppetite()
        return
      }
    }

    if (!application) {
      throw new Error(
        'Unsuccessful conversion of pre-application to application'
      )
    }

    return this.applyApplication(application)
  }

  @Action
  public setInitialSectionData (payload: {
    availableSections: string[]
    sections: Section[]
  }) {
    // initializing the visible section. will use the advanceVisibleSections function to determine the next
    // viable section to display
    const initialSections = advanceVisibleSections(
      [],
      payload.sections,
      this.questions,
      this.answers
    )
    this.initializeSections(initialSections)
  }

  @Action
  public async nextSection () {
    const visibleSections = advanceVisibleSections(
      this.visibleSections,
      this.layout,
      this.questions,
      this.answers
    )
    await this.initializeSections(visibleSections)
  }

  /**
   * Save the answers to either the pre-application or application depending on the current phase.
   */
  @Action({ rawError: true })
  public async saveAnswers () {
    if (this.application) {
      try {
        await store.$apiClient.applications.update(
          this.application.id,
          this.answersToSave
        )
      } catch (err: any) {
        if (err.code === ApplicationErrors.INVALID_ANSWER_VALUE) {
          // delete invalid answer (from a previously saved application) to allow the user to proceed
          const invalidAnswerKey = Object.keys(err.meta)[0]
          delete this.answers[invalidAnswerKey]
        }
      }
    } else if (this.preApplication) {
      const updatedPreApplication =
        await store.$apiClient.preApplications.update(
          this.preApplication.id,
          this.answersToSave
        )
      await Promise.all([
        this.setPreApplication(updatedPreApplication),
        this.setQuestionnaire(updatedPreApplication.questionnaire)
      ])
    } else {
      throw new Error("Can't save questions if there is no app or pre-app")
    }
  }

  @Action({ commit: BinderMutations.ADD_VALIDATION })
  public addValidationMessage (data: ValidationMessage) {
    return data
  }

  @Action({ commit: BinderMutations.REMOVE_VALIDATION })
  public removeValidationMessage (field: string) {
    return field
  }

  @Action({ commit: BinderMutations.UPDATE_FORM_VALIDATION })
  public updateFormValidation (data: ValidationMessage[]) {
    return data
  }

  @Action({ commit: BinderMutations.SET_ACTIVE_CONTROL })
  public setActiveControl (data: string) {
    return data
  }

  @Action({ commit: BinderMutations.SET_TOTAL_SECTIONS })
  public setTotalSections (data: number) {
    return data
  }

  @Action({ commit: BinderMutations.SET_PENDING_QUOTE_CHECKOUT })
  public setPendingQuoteCheckout (id: string | null) {
    return id
  }

  @Action({ commit: BinderMutations.TOGGLE_BUSINESS_INFO })
  public setBusinessInfo () {
    return !this.toggleBusinessInfo
  }

  @Action({ commit: BinderMutations.TOGGLE_SAVE_FOR_LATER })
  public setSaveForLater () {
    return !this.toggleSaveForLater
  }

  @Action({ commit: BinderMutations.TOGGLE_SHARE_QUOTE })
  public setShareQuote () {
    return !this.toggleShareQuote
  }

  @Action({ commit: BinderMutations.TOGGLE_REVIEW_OVERLAY })
  public setReviewOverlay () {
    return !this.toggleReviewOverlay
  }

  @Action({ commit: BinderMutations.TOGGLE_QUOTES_STATUS_DRAWER })
  public setQuotesStatusDrawer () {
    return !this.toggleQuotesStatusDrawer
  }

  @Action({ commit: BinderMutations.TOGGLE_EDIT_QUESTION })
  public setEditQuestion (data: string | Record<string, unknown>) {
    return data
  }

  @Action({ rawError: true })
  public async saveForLater (data: Link) {
    if (!this.tokenData) {
      throw new TokenNotFoundError('Token not found')
    }
    const id = this.tokenData.split('.')[0]

    const { activeToken } = await store.$apiClient.links.update(id, data)
    if (activeToken) {
      sessionStorage.setItem(BRIZA_APPLICATION_TOKEN, activeToken)
      await this.setToken(activeToken)
    }
  }

  @Action({ rawError: true })
  public async resumeApplication () {
    try {
      const res = await store.$apiClient.links.list()
      const {
        id,
        meta: { progress, quoteId }
      } = res.data[0]
      switch (progress) {
        case Progress.preApplication: {
          const preApp = await store.$apiClient.links.preApplications.list(id)
          await this.applyPreApplication(preApp.data[0])
          return {
            progress,
            id: preApp.data[0].id
          }
        }
        case Progress.review:
        case Progress.application:
        case Progress.quotes:
        case Progress.payment: {
          const app = await store.$apiClient.links.applications.list(id)
          await this.applyApplication(app.data[0])
          if (quoteId) {
            this.setPendingQuoteCheckout(quoteId)
          }
          return {
            progress,
            id: app.data[0].id
          }
        }
        case undefined: {
          const preAppCheck = await store.$apiClient.links.preApplications.list(
            id
          )
          if (preAppCheck.data[0].status === PreApplicationStatus.Completed) {
            const appCheck = await store.$apiClient.links.applications.list(id)
            await this.applyApplication(appCheck.data[0])
            return {
              progress: Progress.review,
              id: appCheck.data[0].id
            }
          } else {
            await this.applyPreApplication(preAppCheck.data[0])
            return {
              progress: Progress.preApplication,
              id: preAppCheck.data[0].id
            }
          }
        }
        default:
          throw new Error(
            `unknown ${progress} progress, did not match expected values`
          )
      }
    } catch (error: any) {
      // @ts-ignore
      throw new Error(`resuming failed: ${error.message}`)
    }
  }

  @Action({ commit: BinderMutations.ADD_LOCATION })
  public incrementLocation () {
    if (this.answers.NumberOfLocations) {
      const currentNumberOfLocations = parseInt(
        this.answers.NumberOfLocations as string
      )
      return currentNumberOfLocations + 1
    }
    return 1
  }

  /**
   * Removing location based on question id and location position
   * Since we wont be removing locations from last to first, we need to resort the location address information
   * in the answer object based on the requested location to be removed
   * ie - Have 3 locations and user wants to remove location 2
   * 1- Move Location3Address Information to Location2Address
   * 2- Remove Location3Address from answer object
   * @param questionId
   */
  @Action({ commit: BinderMutations.REMOVE_LOCATION })
  public decrementLocation (questionId: string) {
    if (this.answers.NumberOfLocations) {
      const locationQuestions = getQuestionIdsByType(this.questions, 'Address')
      const locationAnswers = getAnswersByQuestionId(
        this.answers,
        locationQuestions
      )
      const currentNumberOfLocations = parseInt(
        this.answers.NumberOfLocations as string
      )
      const updatedLocationAnswers = removeAddress(
        locationAnswers,
        questionId,
        currentNumberOfLocations
      )
      this.addAnswer(updatedLocationAnswers)
      return currentNumberOfLocations > 0 ? currentNumberOfLocations - 1 : 1
    }
    return 1
  }

  @Action({ commit: BinderMutations.SAVE_LOCATION_GEOMETRY })
  public saveLocationGeometry (data: GeometryInfo) {
    return data
  }

  @Action({ commit: BinderMutations.TOGGLE_CART })
  public setShowCart (): boolean {
    return !this.toggleCart
  }

  @Action({ commit: BinderMutations.TOGGLE_DRAWER })
  public toggleDrawer (): boolean {
    return false
  }

  @Action({ commit: BinderMutations.SET_DRAWER_STATE })
  public setDrawerState (data: boolean): boolean {
    return data
  }

  @Action({ commit: BinderMutations.TOGGLE_HINT })
  public setShowHint (): boolean {
    return !this.toggleHint
  }

  @Action({ commit: BinderMutations.SET_PREAPPLICATION_COMPLETE })
  public setPreApplicationComplete (data = true): boolean {
    return data
  }

  @Action({ commit: BinderMutations.SET_PREAPPLICATION_NOAPPETITE })
  public setPreApplicationNoAppetite (data = true): boolean {
    return data
  }

  @Action({ commit: BinderMutations.SET_PREFILLED_ANSWERS })
  public setPreFilledAnswers (
    answers: Record<string, unknown>
  ): Record<string, unknown> {
    return answers
  }

  @Action
  public async restartApplication (): Promise<string> {
    await this.setToken('')
    await this.setPreApplicationNoAppetite(false)
    await this.setPreApplicationComplete(false)
    const preApplication: PreApplication | undefined =
      await this.createPreApplication()

    if (!preApplication) {
      throw new Error('Unsuccessful in creating a pre-application')
    }

    return preApplication?.id
  }
  // #endregion
}
