import { NavigationGuard } from 'vue-router'
import { IApiClient } from '@/api/abstract/IApiClient'
import { ROUTES } from '../routes'
import { brokerageModule } from '@/store/store-accessor'
import { errorLogger, infoLogger } from './commons'
import { ApiError } from '@/api/abstract/errors'

/**
 * Brokerage slug is being present in the URL
 * as the lowest subdomain, e.g. brokerage-x.domain.com
 */
export function defaultBrokerageSlugFn (): string {
  const host = window.location.hostname
  // web.app is the Firebase Hosting service we use to preview pull requests
  const slug = (host === 'localhost' || host.endsWith('web.app'))
    ? 'briza'
    : host.split('.')[0]
  return slug
}

/**
 * Authentication middleware.
 * Authenticate brokerage when a secured area is visited.
 * (Note: Currently all pages are "secured", requires authentication)
 * In case of authentication failure, redirects to page not found.
 * @param apiClient Api client instance.
 * @param logger Error logger.
 * @param brokerageSlugFn Brokerage slug resolution function.
 */
export const authenticationMiddleware = (
  apiClient: IApiClient,
  logger: { error: errorLogger, info: infoLogger },
  brokerageSlugFn: () => string = defaultBrokerageSlugFn
): NavigationGuard => {
  return (to, from, next) => {
    // Authenticated
    if (apiClient.isAuthenticated) {
      return next()
    }

    apiClient.authenticate(brokerageSlugFn())
      .then(() => {
        return brokerageModule.setBrokerage(apiClient.authenticatedBrokerage)
      })
      .then(() => {
        next()
      })
      .catch((error: ApiError) => {
        if (error.statusCode === 404) {
          logger.info(error.message)
        } else {
          logger.error(error.message, {
            stack: error.stack
          })
        }

        if (to.name !== ROUTES.PAGE_NOT_FOUND) {
          next({ name: ROUTES.PAGE_NOT_FOUND })
        } else {
          next()
        }
      })
  }
}
