import { AnswerMap, Question } from '@/models/questionnaire'
import { engine } from '@/engine'

export function getQuestionIdsByType (questions: Question[], questionType: string): string[] {
  return questions.reduce((questionIds, currentQuestion) => {
    if (currentQuestion.type === questionType) {
      return [
        ...questionIds,
        currentQuestion.id
      ]
    }

    return questionIds
  }, [] as string[])
}

export function getAnswersByQuestionId (answers: AnswerMap, questionIds: string[]): AnswerMap {
  return Object.keys(answers).reduce((answerSubtset, currentKey) => {
    // eslint-disable-next-line array-callback-return
    questionIds.map(questionId => {
      if (questionId === currentKey) {
        answerSubtset = {
          ...answerSubtset,
          [currentKey]: answers[currentKey]
        }
      }
    })
    return answerSubtset
  }, {})
}

export function removeAddress (
  answers: AnswerMap,
  questionId: string,
  numberOfLocations: number): AnswerMap {
  const updatedAnswers = { ...answers }
  // null out the question to be removed.
  updatedAnswers[questionId] = null

  return Object.keys(updatedAnswers).reduce((acc, curr, index) => {
    // current location identifier
    const locationId = index + 1
    // potential next location identifier - will be used to grab the next location address if any
    const nextLocationId = locationId + 1

    // IF
    // 1. current location is null
    // 2. there are still potential locations to get answers from and shuffle up
    // then pop the next location address into current location
    if (updatedAnswers[curr] === null && nextLocationId <= numberOfLocations) {
      // Null out the next postion to trigger the next update in the loop
      updatedAnswers[`Location${nextLocationId}Address`] = null
      return {
        ...acc,
        [curr]: answers[`Location${nextLocationId}Address`]
      }
    }

    // return current address info as is
    return {
      ...acc,
      [curr]: updatedAnswers[curr]
    }
  }, {} as AnswerMap)
}

export function isQuestionVisible (question: Question, answers: AnswerMap) {
  return question.condition ? engine.evaluate(question.condition, answers) : true
}
