


















import { XIcon } from 'vue-feather-icons'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'FeedbackTitle',
  components: { XIcon },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  emits: ['close']
})
