





















































import { Component, Prop, Mixins } from 'vue-property-decorator'
import BrizaMixin from '@/mixins'
import { Question } from '@/models/questionnaire'
import { AlertTriangleIcon, CheckIcon } from 'vue-feather-icons'

@Component({
  name: 'TextArea',
  components: {
    AlertTriangleIcon,
    CheckIcon
  }
})
export default class TextArea extends Mixins(BrizaMixin) {
  @Prop({ default: '' }) placeholder !: string
  @Prop() label!: string
  @Prop() type!: string
  @Prop() error!: string
  @Prop() question!: Question
  @Prop() currentAnswer!: string
  @Prop({ default: false }) isReviewing!: boolean
  answer: string | null = null

  mounted () {
    this.answer = this.currentAnswer ? this.currentAnswer as string : ''
  }

  blurHandler () {
    this.$emit('update-answer', this.answer)
  }

  changeHandler () {
    this.$emit('update-answer', this.answer)
  }
}
