import { ILinksResource } from '@/api/abstract/resources/ILinksResource'
import { Application } from '@/models/application'
import { BrokerageContact, BrokerageProfile } from '@/models/brokerage'
import { COB } from '@/models/cob'
import { Country } from '@/models/country'
import { Jurisdiction } from '@/models/jurisdiction'
import { Link, LinkResponse } from '@/models/link'
import { Policy } from '@/models/policy'
import { PreApplication } from '@/models/pre-application'
import { Product, ProductType } from '@/models/product'
import { InstallmentFrequency } from '@/models/quote'
import { Underwriter } from '@/models/underwriter'
import axios, {
  AxiosAdapter,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
  ResponseType
} from 'axios'
import { cacheAdapterEnhancer, ICacheLike } from 'axios-extensions'
import Fingerprint2 from 'fingerprintjs2'
import { AxiosProxy } from './abstract/AxiosProxy'
import { ApiError } from './abstract/errors'
import { IApiClient } from './abstract/IApiClient'
import {
  defaultPaginationOptions,
  PaginationOptions
} from './abstract/pagination'
import { IAcknowledgementsResource } from './abstract/resources/IAcknowledgementsResource'
import { IApplicationsResource } from './abstract/resources/IApplicationsResource'
import { IBrokeragesResource } from './abstract/resources/IBrokeragesResource'
import { IClassOfBusinessResource } from './abstract/resources/IClassOfBusinessResource'
import { ICountriesResource } from './abstract/resources/ICountriesResource'
import { IPoliciesResource } from './abstract/resources/IPoliciesResource'
import {
  IPreApplicationsResource,
  PreApplicationToken
} from './abstract/resources/IPreApplicationsResource'
import { IProductsResource } from './abstract/resources/IProductsResource'
import { IQuoteCoveragesResource } from './abstract/resources/IQuoteCoveragesResource'
import {
  Header,
  IQuoteProposalResource
} from './abstract/resources/IQuoteProposalResource'
import { IQuotesResource } from './abstract/resources/IQuotesResource'
import { IUnderwritersResource } from './abstract/resources/IUnderwritersResource'
import { IWcClassResource } from './abstract/resources/IWcClassResource'
import { WcClass } from '@/models/wc-class'
import {
  ApiResponseList,
  ApiResponseListPaginated,
  ApiResponseSingle,
  ApiResponseTuple
} from './abstract/response'
import {
  AxiosRequestConfigExtended,
  config,
  createAxiosProxy,
  header,
  query
} from './utils'
import { IFeedbackResource } from './abstract/resources/IFeedbackResource'
import { IProductTypeResource } from './abstract/resources/IProductTypeResource'

/**
 * Briza api client.
 */
export class ApiClient implements IApiClient {
  private brokerage?: BrokerageProfile;
  private fingerprint?: string;
  private readonly debug: boolean;
  private readonly fingerprintProcess: Promise<void>;
  private readonly publicApi: AxiosProxy;

  /**
   * Secured api accessor.
   * - X-TOKEN is automatically added into each request.
   * - Client must be authenticated to use this, otherwise it throws.
   */
  private readonly securedApi: AxiosProxy;

  /**
   * @constructor
   * * @param publicUrl API bff URL.
   * * @param secureUrl API secure URL.
   */
  constructor (
    debug: boolean,
    publicUrl = `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_BFF_PATH}/`,
    secureUrl = `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_BASE_PATH}/`
  ) {
    this.debug = debug
    this.publicApi = createAxiosProxy(
      axios.create({
        baseURL: publicUrl,
        headers: { 'Cache-Control': 'no-cache' },
        // disable the default cache and set the cache flag
        adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
          enabledByDefault: false,
          cacheFlag: 'useCache'
        })
      }),
      this.apiRequest.bind(this)
    )
    this.securedApi = createAxiosProxy(
      axios.create({
        baseURL: secureUrl,
        headers: { 'Cache-Control': 'no-cache' },
        // disable the default cache and set the cache flag
        adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
          enabledByDefault: false,
          cacheFlag: 'useCache'
        })
      }),
      this.apiRequest.bind(this)
    )

    // Request / response middlewares
    this.publicApi.axios.interceptors.response.use(
      undefined,
      this.captureApiError.bind(this)
    )
    this.securedApi.axios.interceptors.request.use(
      this.authenticationCheck.bind(this)
    )
    this.securedApi.axios.interceptors.request.use(this.addTokenHeader)
    this.securedApi.axios.interceptors.request.use(
      this.addFraudGuardIdHeader.bind(this)
    )
    this.securedApi.axios.interceptors.response.use(
      undefined,
      this.captureApiError.bind(this)
    )

    // Set fingerprint in an async call
    this.fingerprintProcess = this.setFingerprint()
  }

  /**
   * Authentication.
   * Exchange brokerage slug for brokerage uuid.
   * @param brokerageSlug
   * @throws [[BrizaApiError]] Expected API error.
   */
  async authenticate (brokerageSlug: string): Promise<void> {
    if (this.isAuthenticated) {
      return
    }

    const cancelToken = this.getCancelToken()
    try {
      this.brokerage = await this.brokerages.get(
        brokerageSlug,
        cancelToken.token
      )
    } catch (error: any) {
      cancelToken.cancel()
      throw error
    }
  }

  async brokerageMetadata (): Promise<void> {
    if (this.authenticatedBrokerage?.contacts) {
      return
    }
    const cancelToken = this.getCancelToken()

    try {
      const [contacts] = await Promise.all([
        this.brokerages.contacts.list(
          this.authenticatedBrokerage.id,
          defaultPaginationOptions,
          cancelToken.token
        )
      ])
      this.brokerage = {
        ...this.authenticatedBrokerage,
        contacts: contacts.data
      }
    } catch (error: any) {
      cancelToken.cancel()
      throw error
    }
  }

  /**
   * Authenticated state flag.
   */
  get isAuthenticated (): boolean {
    return !!this.brokerage
  }

  /**
   * Authenticated brokerage details.
   */
  get authenticatedBrokerage (): BrokerageProfile {
    if (this.isAuthenticated === false) {
      throw new Error('the brokerage has not been retrieved')
    }
    return this.brokerage as BrokerageProfile
  }

  get brokerages (): IBrokeragesResource {
    const resource = 'brokerages'
    return {
      get: (
        slug: string,
        cancelToken?: CancelToken
      ): ApiResponseSingle<BrokerageProfile> =>
        this.publicApi.get(
          `${resource}/${slug}`,
          config([this.cancelToken(cancelToken)])
        ),

      contacts: {
        list: (
          id: string,
          pagination: PaginationOptions,
          cancelToken?: CancelToken
        ): ApiResponseListPaginated<BrokerageContact> =>
          this.securedApi.get(
            `${resource}/${id}/contacts${query({ pagination })}`,
            config([this.cancelToken(cancelToken)])
          )
      }
    }
  }

  get applications (): IApplicationsResource {
    const resource = 'applications'
    return {
      create: (
        preApplicationId: string,
        cancelToken?: CancelToken
      ): ApiResponseSingle<Application> =>
        this.securedApi.post(
          'applications',
          { preApplicationId },
          config([this.cancelToken(cancelToken)])
        ),
      get: (
        id: string,
        cancelToken?: CancelToken
      ): ApiResponseSingle<Application> =>
        this.securedApi.get(
          `${resource}/${id}`,
          config([this.cancelToken(cancelToken)])
        ),

      update: (
        id: string,
        answers: object,
        cancelToken?: CancelToken
      ): ApiResponseSingle<Application> =>
        this.securedApi.patch(
          `${resource}/${id}`,
          { answers },
          config([this.cancelToken(cancelToken)])
        ),

      products: {
        list: (
          id: string,
          pagination: PaginationOptions,
          cancelToken?: CancelToken
        ): ApiResponseListPaginated<Product> =>
          this.securedApi.get(
            `${resource}/${id}/products${query({
              brokerageId: this.authenticatedBrokerage.id,
              pagination
            })}`,
            config([this.cancelToken(cancelToken)])
          )
      },

      quotes: this.quotes
    }
  }

  private get quotes (): IQuotesResource {
    return {
      create: (applicationId, cancelToken) =>
        this.securedApi.post(
          'quotes',
          { applicationId },
          config([this.cancelToken(cancelToken)])
        ),

      list: (applicationId, pagination, cancelToken) =>
        this.securedApi.get(
          `quotes${query({ applicationId, pagination })}`,
          config([this.cancelToken(cancelToken)])
        ),

      get: (quoteId, cancelToken) =>
        this.securedApi.get(
          `quotes/${quoteId}`,
          config([this.cancelToken(cancelToken)])
        ),

      update: (quoteId, answers, cancelToken) =>
        this.securedApi.patch(
          `quotes/${quoteId}`,
          { coverageAnswers: answers },
          config([this.cancelToken(cancelToken)])
        ),

      acknowledgements: this.acknowledgements,
      policies: this.policies,
      coverages: this.quoteCoverages,
      proposals: this.quoteProposals
    }
  }

  private get quoteProposals (): IQuoteProposalResource {
    return {
      get: (
        quoteId: string,
        cancelToken: CancelToken
      ): ApiResponseTuple<Blob, Header> =>
        this.securedApi.get(
          `quotes/${quoteId}/proposal`,
          config([
            this.cancelToken(cancelToken),
            this.addResponseType('arraybuffer'),
            this.acceptTypeHeader('application/pdf'),
            this.receiveFileWithHeaders()
          ])
        )
    }
  }

  private get acknowledgements (): IAcknowledgementsResource {
    return {
      get: (quoteId: string, cancelToken?: CancelToken) =>
        this.securedApi.get(
          `quotes/${quoteId}/acknowledgements`,
          config([this.cancelToken(cancelToken)])
        ),

      update: (
        quoteId: string,
        answers: object,
        cancelToken?: CancelToken
      ): ApiResponseSingle<void> =>
        this.securedApi.patch(
          `quotes/${quoteId}`,
          { acknowledgementAnswers: answers },
          config([this.cancelToken(cancelToken)])
        )
    }
  }

  private get quoteCoverages (): IQuoteCoveragesResource {
    return {
      get: (quoteId, cancelToken) =>
        this.securedApi.get(
          `quotes/${quoteId}/coverages`,
          config([this.cancelToken(cancelToken)])
        )
    }
  }

  private get policies (): IPoliciesResource {
    return {
      create: (
        quoteId: string,
        installmentFrequency: InstallmentFrequency,
        skipPayment?: boolean,
        payment?: object,
        cancelToken?: CancelToken
      ): ApiResponseSingle<Policy> =>
        this.securedApi.post(
          'policies',
          {
            quoteId,
            installmentFrequency,
            skipPayment,
            payment
          },
          config([this.cancelToken(cancelToken)])
        ),

      list: (
        quoteId: string,
        pagination: PaginationOptions,
        cancelToken?: CancelToken
      ): ApiResponseListPaginated<Policy> =>
        this.securedApi.get(
          `policies${query({ quoteId, pagination })}`,
          config([this.cancelToken(cancelToken)])
        ),

      get: (
        policyId: string,
        cancelToken?: CancelToken
      ): ApiResponseSingle<Policy> =>
        this.securedApi.get(
          `policies/${policyId}`,
          config([this.cancelToken(cancelToken)])
        ),

      update: (
        policyId: string,
        payment?: object,
        cancelToken?: CancelToken
      ): ApiResponseSingle<Policy> =>
        this.securedApi.patch(
          `policies/${policyId}`,
          { payment },
          config([this.cancelToken(cancelToken)])
        )
    }
  }

  get classOfBusinesses (): IClassOfBusinessResource {
    const resource = 'business-classes'
    return {
      list: (
        productTypeSlug?: string,
        cancelToken?: CancelToken,
        skipBrokerageIdFilter = false
      ): ApiResponseListPaginated<COB> =>
        this.securedApi.get(
          `${resource}${query({
            productTypeSlug,
            brokerageId: skipBrokerageIdFilter
              ? undefined
              : this.authenticatedBrokerage.id
          })}`,
          config([this.cancelToken(cancelToken)])
        )
    }
  }

  get wcClasses (): IWcClassResource {
    const resource = 'workers-compensation-class-codes'
    return {
      list: (
        state: string,
        cancelToken?: CancelToken
      ): ApiResponseListPaginated<WcClass> =>
        this.securedApi.get(
          `${resource}${query({ state })}`,
          config([this.cancelToken(cancelToken), this.useCache()])
        )
    }
  }

  get countries (): ICountriesResource {
    const resource = 'countries'
    return {
      list: (
        pagination: PaginationOptions,
        cancelToken?: CancelToken
      ): ApiResponseListPaginated<Country> =>
        this.securedApi.get(
          `${resource}${query({ pagination })}`,
          config([this.cancelToken(cancelToken)])
        ),

      jurisdictions: {
        list: (
          id: string,
          pagination: PaginationOptions,
          cancelToken?: CancelToken
        ): ApiResponseListPaginated<Jurisdiction> =>
          this.securedApi.get(
            `${resource}/${id}/jurisdictions${query({ pagination })}`,
            config([this.cancelToken(cancelToken)])
          )
      }
    }
  }

  get products (): IProductsResource {
    const resource = 'products'
    return {
      list: (
        pagination: PaginationOptions,
        cancelToken?: CancelToken
      ): ApiResponseListPaginated<Product> =>
        this.securedApi.get(
          `${resource}${query({
            brokerageId: this.authenticatedBrokerage.id,
            pagination
          })}`,
          config([this.cancelToken(cancelToken)])
        )
    }
  }

  get productTypes (): IProductTypeResource {
    const resource = 'product-types'
    return {
      list: (
        pagination: PaginationOptions,
        cancelToken?: CancelToken
      ): ApiResponseListPaginated<ProductType> =>
        this.securedApi.get(
          `${resource}${query({ pagination })}`,
          config([this.cancelToken(cancelToken)])
        )
    }
  }

  get underwriters (): IUnderwritersResource {
    const resource = 'underwriters'
    return {
      list: (
        pagination: PaginationOptions,
        cancelToken?: CancelToken
      ): ApiResponseListPaginated<Underwriter> =>
        this.securedApi.get(
          `${resource}${query({ pagination })}`,
          config([this.cancelToken(cancelToken)])
        )
    }
  }

  get preApplications (): IPreApplicationsResource {
    const resource = 'pre-applications'
    return {
      create: (
        timeZone: string,
        cancelToken?: CancelToken
      ): ApiResponseSingle<[PreApplication, PreApplicationToken]> =>
        this.publicApi.post(
          `${resource}`,
          { brokerageId: this.authenticatedBrokerage.id, timeZone },
          config([this.cancelToken(cancelToken), this.receiveToken()])
        ),

      get: (
        id: string,
        cancelToken?: CancelToken
      ): ApiResponseSingle<PreApplication> =>
        this.securedApi.get(
          `${resource}/${id}`,
          config([this.cancelToken(cancelToken)])
        ),

      update: (
        id: string,
        answers: object,
        cancelToken?: CancelToken
      ): ApiResponseSingle<PreApplication> =>
        this.securedApi.patch(
          `${resource}/${id}`,
          { answers },
          config([this.cancelToken(cancelToken)])
        )
    }
  }

  get links (): ILinksResource {
    const resource = 'links'
    return {
      list: (
        cancelToken?: CancelToken
      ): ApiResponseListPaginated<LinkResponse> =>
        this.securedApi.get(
          `${resource}`,
          config([this.cancelToken(cancelToken)])
        ),

      preApplications: {
        list: (
          id: string,
          cancelToken?: CancelToken
        ): ApiResponseListPaginated<PreApplication> =>
          this.securedApi.get(
            `${resource}/${id}/pre-applications`,
            config([this.cancelToken(cancelToken)])
          )
      },
      applications: {
        list: (
          id: string,
          cancelToken?: CancelToken
        ): ApiResponseListPaginated<Application> =>
          this.securedApi.get(
            `${resource}/${id}/applications`,
            config([this.cancelToken(cancelToken)])
          )
      },

      update: (
        id: string,
        data: Link,
        cancelToken?: CancelToken
      ): ApiResponseSingle<LinkResponse> =>
        this.securedApi.patch(
          `${resource}/${id}`,
          data,
          config([this.cancelToken(cancelToken)])
        )
    }
  }

  get feedback (): IFeedbackResource {
    const resource = 'feedback'
    return {
      create: (payload: object, cancelToken?: CancelToken) =>
        this.securedApi.post(
          `${resource}`,
          payload,
          config([this.cancelToken(cancelToken)])
        )
    }
  }

  /**
   * Get axios request cancel token
   */
  getCancelToken (): CancelTokenSource {
    return axios.CancelToken.source()
  }

  /**
   * Internal api request handler / wrapper.
   * @param url Request url.
   * @param request axios request promise.
   */
  private async apiRequest<T> (
    url: string,
    request: Promise<AxiosResponse<T>>
  ): Promise<T> {
    try {
      const response = await request
      if (response.data) {
        return response.data
      }

      // Void type, a.k.a 200 response only.
      return undefined as unknown as T
    } catch (error: any) {
      if (axios.isCancel(error) && this.debug) {
        throw new Error(`request ${url} has been canceled`)
      }
      throw error
    }
  }

  /**
   * Add security token into headers.
   *
   * Axios request config middleware function.
   * @param config
   */
  private addTokenHeader (config: AxiosRequestConfig): AxiosRequestConfig {
    const token = sessionStorage.getItem('briza-application-token')
    if (token) {
      return header('X-TOKEN', token)(config)
    }
    return config
  }

  /**
   * Add fraud guard id into headers.
   *
   * Axios request config middleware function.
   * @param config
   */
  private async addFraudGuardIdHeader (
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> {
    return header('X-FRAUD-GUARD-ID', await this.getFingerprint())(config)
  }

  private acceptTypeHeader (
    acceptType: string
  ): (config: AxiosRequestConfig) => AxiosRequestConfig {
    return header('Accept', acceptType)
  }

  private addResponseType (
    responseType: ResponseType
  ): (config: AxiosRequestConfig) => AxiosRequestConfig {
    return (config: AxiosRequestConfig): AxiosRequestConfig => {
      config.responseType = responseType
      return config
    }
  }

  /**
   * Receive application token stored from response transformation.
   * The response data are converted into [data, token] tuple.
   */
  private receiveToken (): (config: AxiosRequestConfig) => AxiosRequestConfig {
    return (config: AxiosRequestConfig): AxiosRequestConfig => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      config.transformResponse = (data: string): any => {
        const payload = JSON.parse(data) as { links: LinkResponse[] }
        const activeToken = payload.links?.find(
          (link) => link.type === 'policyholder'
        )?.activeToken
        if (activeToken) {
          return [payload, activeToken]
        }
        return data
      }
      return config
    }
  }

  /**
   * Reive files with response headers via response transformation.
   * The response data are converted into [data, headers] tuple.
   */
  private receiveFileWithHeaders (): (
    config: AxiosRequestConfig
  ) => AxiosRequestConfig {
    return (config: AxiosRequestConfig): AxiosRequestConfig => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      config.transformResponse = (data: any, headers?: any): any => {
        return [data, headers]
      }
      return config
    }
  }

  /**
   * Authentication check
   *
   * Axios request config middleware function.
   * @param config
   */
  private authenticationCheck (config: AxiosRequestConfig): AxiosRequestConfig {
    if (this.isAuthenticated === false) {
      throw new Error('the brokerage has not been retrieved')
    }
    return config
  }

  /**
   * Axios request options cancel token.
   * @param token Axios cancel token.
   */
  private cancelToken (
    token?: CancelToken
  ): (config: AxiosRequestConfig) => AxiosRequestConfig {
    return (config: AxiosRequestConfig): AxiosRequestConfig => {
      if (token) {
        config.cancelToken = token
      }
      return config
    }
  }

  /**
   * Enable caching for a specific request.
   * @param cache pass in a specific cache object to override the default 5 minute cache
   */
  private useCache (cache?: ICacheLike<any>): (
    // eslint-disable-line @typescript-eslint/no-explicit-any
    config: AxiosRequestConfigExtended
  ) => AxiosRequestConfigExtended {
    return (config: AxiosRequestConfigExtended): AxiosRequestConfigExtended => {
      config.useCache = cache || true
      return config
    }
  }

  /**
   * Capture Briza API error response.
   *
   * Axios response error middleware function.
   * @param error axios error response.
   */
  private captureApiError (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
  ) {
    if (error?.response?.data?.code) {
      return Promise.reject(
        new ApiError(
          error.response.config.method,
          error.response.status,
          error.response.data.code,
          error.response.data.message,
          error.response.data?.meta
        )
      )
    }
    return Promise.reject(error)
  }

  /**
   * Get user browser/env fingerprint hash.
   */
  private async getFingerprint (): Promise<string> {
    // Wait for the setFingerprint.
    await this.fingerprintProcess

    if (!this.fingerprint && this.debug) {
      throw new Error('failed to generate fingerprint')
    }

    return this.fingerprint || ''
  }

  /**
   * Set user browser/env fingerprint hash.
   */
  private async setFingerprint (): Promise<void> {
    return new Promise((resolve) => {
      /**
       * Generate and store the fingerprint from detected browser components.
       * @param components
       */
      const saveFingerprint = (components: Fingerprint2.Component[]) => {
        this.fingerprint = Fingerprint2.x64hash128(
          components.map((component) => component.value).join(''),
          31
        )
        resolve()
      }

      // Fingerprint2 usage: https://github.com/Valve/fingerprintjs2#usage
      if (window.requestIdleCallback) {
        window.requestIdleCallback(() => {
          Fingerprint2.get((components: Fingerprint2.Component[]) =>
            saveFingerprint(components)
          )
        })
      } else {
        setTimeout(() => {
          Fingerprint2.get((components: Fingerprint2.Component[]) =>
            saveFingerprint(components)
          )
        }, 500)
      }
    })
  }
}
