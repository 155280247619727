






























import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'LoadingIcon',
  props: {
    size: { required: false, default: '20', type: String },
    fill: { required: false, default: '#1578B2', type: String }
  },
  setup (props) {
    return {
      props
    }
  }
})

