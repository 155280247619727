export interface Product {
  id: string;
  productTypeId: string;
  underwriterId: string;
  learnMoreUrl: string;
  version: string;
  createdAt: string;
  updatedAt: string;
  url: string;
}

export interface ProductType {
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
}

export enum ProductNames {
  BusinessOwnersPolicy = 'Business Owner’s Policy',
  ErrorsAndOmissions = 'Professional Liability',
  GeneralLiability = 'General Liability',
  WorkersCompensation = 'Workers’ Compensation',
}

export interface ProductList {
  slug: string;
  name: string;
}
