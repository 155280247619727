import { ref, onBeforeUnmount, onMounted } from '@vue/composition-api'

export default function useScroll () {
  const scrollPosition = ref(0)

  const onScroll = () => {
    scrollPosition.value = Math.round(window.pageYOffset)
  }

  onMounted(() => {
    window.addEventListener('scroll', onScroll)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll)
  })

  return {
    scrollPosition
  }
}
