/**
 * Route names.
 */
export const ROUTES = {
  HOME: 'home',
  PRE_APPLICATION: 'pre-application',
  APPLICATION: 'application',
  QUOTES: 'quotes',
  ACKNOWLEDGMENTS: 'acknowledgements',
  CHECKOUT: 'checkout',
  CHECKOUT_CONFIRMATION: 'checkout-confirmation',
  PAGE_NOT_FOUND: 'page-not-found',
  LINK_NOT_FOUND: 'link-not-found',
  EXPIRED: 'expired',
  QUOTES_TIMEOUT: 'quotes-timeout',
  REVIEW: 'review'
}
