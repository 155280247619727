import { Module, VuexModule, Action } from 'vuex-module-decorators'
import store from '@/store'

@Module({ dynamic: true, store, name: 'FeedbackModule' })
export default class FeedbackModule extends VuexModule {
  @Action({ rawError: true })
  public async submit (data: Record<string, any>) {
    await store.$apiClient.feedback.create(data)
  }
}
