import Vue from 'vue'
import { Store } from 'vuex'
import * as VueGoogleMaps from 'vue2-google-maps'
import store from '@/store'
import './filters'
import DatadogLogger from './plugins/datadog-logger'
import App from './App.vue'
import { IApiClient } from './api/abstract/IApiClient'
import { ApiClient } from './api/ApiClient'
import { createRouter } from './router/router'
import '@/assets/css/tailwind.css'
import VueTheMask from 'vue-the-mask'
import { ServicesProvider } from './plugins/services-provider'
import Toast from 'vue-toastification'
import VueCurrencyInput from 'vue-currency-input'
import * as FullStory from '@fullstory/browser'
import VueCompositionAPI from '@vue/composition-api'

/**
 * Binder application.
 */
class Binder {
  private readonly debug: boolean
  private readonly store: Store<unknown>
  private readonly apiClient: IApiClient

  /**
   * @constructor
   * @param debug Debug flag
   */
  constructor (debug: boolean) {
    this.debug = debug
    this.apiClient = new ApiClient(this.debug)
    this.store = store

    // Fullstory init
    FullStory.init({
      orgId: 'STM0K',
      devMode: process.env.VUE_APP_APP_ENV !== 'production'
    })

    this.initialize()
  }

  /**
   * Initialize the vue application.
   */
  private initialize () {
    this.plugins()

    new Vue({
      router: createRouter(this.apiClient),
      store: this.store,
      render: (createElement) => createElement(App)
    }).$mount('#app')
  }

  /**
   * Register vue plugins.
   */
  private plugins () {
    Vue.use(VueCompositionAPI)
    Vue.use(VueTheMask)
    Vue.use(VueCurrencyInput)
    Vue.use(DatadogLogger, {
      // Some garbage token is required in local/development environments, hence 'unset'
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN ?? 'unset',
      datacenter: 'us'
    })

    Vue.use(VueGoogleMaps, {
      load: {
        key: process.env.VUE_APP_GOOGLE_KEY ?? '',
        libraries: ['places']
      }
    })

    Vue.use(new ServicesProvider(this.store, {
      apiClient: this.apiClient
    }))

    Vue.use(Toast, {
      transition: 'Vue-Toastification__fade',
      position: 'bottom-center',
      timeout: 4000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      hideProgressBar: true,
      closeButton: false,
      icon: true,
      rtl: false
    })
  }
}

export default new Binder(process.env.APP_ENV === 'test')
