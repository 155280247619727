



















import { Component, Prop, Mixins } from 'vue-property-decorator'
import BrokerageMixin from '@/mixins/brokerage-mixin'

@Component({
  name: 'IconBase'
})
export default class IconBase extends Mixins(BrokerageMixin) {
  @Prop({ default: 25 }) width!: number
  @Prop({ default: 25 }) height!: number
  @Prop({ default: '' }) iconName!: string

  get iconColor (): string {
    return this.brandColor
  }

  get viewBox (): string {
    switch (this.iconName) {
      case 'map-pin':
        return '0 0 509 509'
      case 'shield':
        return '0 0 307 393'
      default:
        return '0 0 393 393'
    }
  }
}

