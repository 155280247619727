import { Component, Prop, Vue } from 'vue-property-decorator'
import EventBus from '@/event-bus'
import { binderModule } from '@/store/store-accessor'
import { validateQuestion, isAnswerValid } from '@/helpers/validations'
import { ValidationMessage } from '@/models/validation-messages'
import { Question, AnswerValue } from '@/models/questionnaire'
import { Events } from '@/models/events'

@Component
export default class BrizaMixin extends Vue {
  @Prop() question!: Question

  /**
   * registering string answer against global state through event bus
   * @param event
   */
  sendTextAnswer (event: Event) {
    const answer = (event.target as HTMLInputElement).value
    EventBus.$emit(Events.UPDATE_ANSWER, this.question.id, answer.trim())
  }

  sendAnswer (answer: AnswerValue, question = this.question) {
    if (!isAnswerValid(question, answer)) {
      return
    }
    EventBus.$emit(Events.UPDATE_ANSWER, question.id, answer)
  }

  /**
   * function determining if answer is valid and updating the store with corresponding error message
   * @param answer
   * @param question
   */
  isValid (answer: AnswerValue, question = this.question) {
    const validation = validateQuestion(question, answer)
    if (validation.valid) {
      binderModule.removeValidationMessage(question.id)
    } else {
      binderModule.addValidationMessage(validation.error as ValidationMessage)
    }
  }
}
