import {
  AnswerMap,
  LayoutSection,
  Question,
  SectionMetaLabel
} from '@/models/questionnaire'
import { Underwriter } from '@/models/underwriter'
import { Product, ProductList, ProductType } from '@/models/product'
import { engine } from '@/engine'
import { Quote, QuoteCoverages, sortOptions, QuoteStatus } from '@/models/quote'
import { QuoteFilterItemsOptions } from '@/models/filter'
import BigNumber from 'bignumber.js'
import { Policy, PolicyStatus } from '@/models/policy'

export function searchQuestionListById (
  questionId: string,
  answers: AnswerMap
): string {
  const searchRegex = new RegExp('^' + questionId.replace(/\*/g, '.*') + '$')
  const qId = Object.keys(answers).find((key) => searchRegex.test(key))
  return qId || ''
}

export function findQuoteById (quotesData: Quote[], quoteId: string) {
  return quotesData.find((quote) => quote.id === quoteId)
}

export function sortQuotes (quotes: Quote[], sortDirection: sortOptions) {
  return quotes.sort((a, b) => {
    const quote1PolicyPremium = new BigNumber(a.policyPremium)
    const quote2PolicyPremium = new BigNumber(b.policyPremium)
    return (
      quote1PolicyPremium.comparedTo(quote2PolicyPremium) *
      (sortDirection === sortOptions.DESC ? -1 : 1)
    )
  })
}

/**
 * Filtering quotes by product, coverage, and carrier
 *
 * @param   {Quote[]}           quotes             [Quoted quotes array]
 * @param   {QuoteCoverages[]}  coverageData       [Quote quotes coverages]
 * @param   {string[]}          selectedCarriers   [Carriers to filter by]
 * @param   {string[]}          selectedCoverages  [Coverages to filter by]
 * @param   {string[]}          productSelected    [Product to filter by]
 *
 * @return  {Quote[]}                              [return description]
 */
export function filterQuotes (
  quotes: Quote[],
  coverageData: QuoteCoverages[],
  selectedCarriers: string[],
  selectedCoverages: string[],
  productSelected: string
): Quote[] {
  return quotes.filter((quote) => {
    let carriersFilter = true
    let hasAllCoverages = true
    const quoteCoverages = coverageData.find(
      (coverage) => coverage.quoteId === quote.id
    )?.questions

    if (selectedCarriers.length > 0) {
      carriersFilter = selectedCarriers.includes(quote.underwriterSlug)
    }

    if (selectedCoverages.length > 0 && quoteCoverages) {
      hasAllCoverages =
        quoteCoverages.filter((coverage) =>
          selectedCoverages.includes(
            coverage.shortQuestion ?? (coverage.questionWithinGroup as string)
          )
        ).length === selectedCoverages.length
    }

    if (
      selectedCarriers.length > 0 ||
      selectedCoverages.length > 0 ||
      productSelected
    ) {
      return (
        carriersFilter &&
        hasAllCoverages &&
        quote.productSlug === productSelected
      )
    }

    return true
  })
}

/**
 * Extracting key, label, and hint data of each coverage and pushing to an array to hold all coverages data
 * @param arr
 * @param coveragesArr
 */
interface CoveragesArr {
  question: Question | undefined;
  answers: AnswerMap;
}

function getQualifiedQuotes (
  quotes: Quote[]
): Quote[] {
  return quotes.filter(quote =>
    [QuoteStatus.QUOTED, QuoteStatus.REFERRED].includes(quote.status)
  )
}

function pushCoverageToArr (
  arr: QuoteFilterItemsOptions[],
  coveragesArr: CoveragesArr[]
): QuoteFilterItemsOptions[] {
  // eslint-disable-next-line array-callback-return
  coveragesArr.map((coverage) => {
    const coverageExists =
      arr.find(
        (cv) =>
          cv.label ===
          (coverage.question?.shortQuestion ??
            coverage.question?.questionWithinGroup)
      ) !== undefined
    const isCoverageVisible =
      coverage.question && coverage.question.condition
        ? engine.evaluate(coverage.question.condition, coverage.answers)
        : true
    if (!coverageExists && coverage.question && isCoverageVisible) {
      arr.push({
        key: coverage.question.id,
        label:
          (coverage.question.shortQuestion ??
            coverage.question.questionWithinGroup) ||
          '',
        hint: coverage.question.ui.hint ?? [],
        quotesId: []
      })
    }
  })

  return arr
}

/**
 * Extract unique coverage set from all quote coverages array
 * also filter out any conditional coverages from list
 * and associates which quoteIds have each coverage (to disable in the filters when not displaying)
 * @param coverageData
 */
export function extractCoverages (
  coverageData: QuoteCoverages[]
): QuoteFilterItemsOptions[] {
  return coverageData
    .map((coverageData) =>
      coverageData.layout
        .flatMap((layout) => layout.items)
        .filter((item): item is LayoutSection => typeof item === 'object')
        .filter(
          (item) =>
            item.meta.label === SectionMetaLabel.IncludedCoverages ||
            item.meta.label === SectionMetaLabel.OptionalCoverages
        )
        .flatMap((item) => item.items)
        .map((questionId) => {
          return {
            question: coverageData.questions.find(
              (question) => question.id === questionId
            ),
            answers: coverageData.answers
          }
        })
        .filter((coverage) => coverage.question !== undefined)
    )
    .reduce<QuoteFilterItemsOptions[]>(pushCoverageToArr, [])
    .sort((a, b) => (a.key < b.key ? -1 : 1))
    .map(o => ({
      ...o,
      quotesId:
        coverageData.filter((data) => {
          return JSON.stringify(data).includes(o.key)
        }).map(a => a.quoteId)
    }))
}

export function getUnderwriterSlug (
  productList: Product[],
  underwriterList: Underwriter[],
  productId: string
): string {
  return (
    underwriterList.find(
      (underwriter) =>
        underwriter.id ===
        productList.find((product) => product.id === productId)?.underwriterId
    )?.slug ?? ''
  )
}

export function extractCarrierData (
  quotes: Quote[],
  underwriterList: Underwriter[]
): QuoteFilterItemsOptions[] {
  const quoteList = getQualifiedQuotes(quotes)
  return quoteList.map((quote) => ({
    key: quote.underwriterSlug,
    label:
      underwriterList.find(
        (underwriter) => underwriter.slug === quote.underwriterSlug
      )?.name ?? '',
    hint: [],
    quotesId: []
  })).filter(item => item.key !== '')
}

export function extractProductData (
  quotes: Quote[],
  productList: Product[],
  productTypeList: ProductType[]
): ProductList[] {
  const quoteList = getQualifiedQuotes(quotes)
  return quoteList.map((quote) => {
    const product = productList.find((product) => product.id === quote.productId)
    const productType = productTypeList.find((productType) => productType.id === product?.productTypeId)
    return {
      slug: productType?.slug ?? '',
      name: productType?.name ?? ''
    }
  })
}

export function getProductSlug (quote: Quote, productList: Product[], productTypeList: ProductType[]): string {
  const product = productList.find((product) => product.id === quote.productId)
  return productTypeList.find((productType) => productType.id === product?.productTypeId)?.slug ?? ''
}

export function isPolicyBound (policy: Policy): boolean {
  return [PolicyStatus.Bound, PolicyStatus.ToBeBound].includes(policy.status as PolicyStatus)
}

export function isPolicyQueued (policy: Policy): boolean {
  return policy.status === PolicyStatus.Queued
}

export function isPolicyFailed (policy: Policy): boolean {
  return policy.status === PolicyStatus.Failed
}
