import { binderModule } from '@/store/store-accessor'
import { NavigationGuard } from 'vue-router'
import { parseValues } from './url/values-parser'

export const defaultValuesMiddleware = (): NavigationGuard =>
  (to, from, next) => {
    if (to.path !== '/' || to.hash.trim().length === 0) {
      return next()
    }

    const answers = parseValues(to.hash)

    binderModule.setPreFilledAnswers(answers)

    return next({
      path: to.path,
      hash: ''
    })
  }
