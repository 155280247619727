/**
 * Expected API error.
 */
export class ApiError extends Error {
  public readonly name: string
  public readonly code: string
  public readonly statusCode: number
  public readonly method: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public readonly meta?: any

  /**
   * @constructor
   * @param method HTTP request method.
   * @param statusCode HTTP status code.
   * @param code Error code.
   * @param message Error message.
   * @param meta Error meta data.
   */
  constructor (
    method: string,
    statusCode: number,
    code: string,
    message: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta?: any
  ) {
    super(`api error [${method}] (${statusCode}) ${code}: ${message}`)
    this.name = this.constructor.name
    this.code = code
    this.meta = meta
    this.method = method
    this.statusCode = statusCode
  }
}
