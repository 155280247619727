import { ref } from '@vue/composition-api'

export default function useLoadable () {
  const loading = ref(false)
  const btnLoading = ref(false)
  const showLoading = () => {
    loading.value = true
  }
  const hideLoading = () => {
    loading.value = false
  }
  const showBtnLoading = () => {
    btnLoading.value = true
  }
  const hideBtnLoading = () => {
    btnLoading.value = false
  }
  return {
    loading,
    btnLoading,
    showLoading,
    hideLoading,
    showBtnLoading,
    hideBtnLoading
  }
}
