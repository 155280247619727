
export const enum Events {
  UPDATE_QUOTE = 'update-quote',
  UPDATE_ANSWER = 'update-answer',
  SELECT_PRODUCT = 'select-product',
  SHOW_QUOTE_STATUS = 'show-quote-status',
  TOGGLE_HINT = 'toggle-hint',
  RESET_QUOTES = 'reset-quotes',
  CLOSE_DRAWER = 'close-drawer'
}

export const enum NavigationDestinations {
  QUOTES_VIEW = 'quotes-view',
  QUOTES_STATUS = 'quotes-status'
}
