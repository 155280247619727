/**
 * Collection of api client utilities functions.
 */
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import { AxiosProxy } from './abstract/AxiosProxy'
import { ICacheLike } from 'axios-extensions'

/**
 * Axios proxy factory.
 * @param axios
 * @param requestHandler
 */
export function createAxiosProxy (
  axios: AxiosInstance,
  requestHandler: <T>(url: string, request: Promise<AxiosResponse<T>>) => Promise<T>
): AxiosProxy {
  return {
    axios,
    get: (...args) => requestHandler(args[0], axios.get(...args)),
    post: (...args) => requestHandler(args[0], axios.post(...args)),
    put: (...args) => requestHandler(args[0], axios.put(...args)),
    patch: (...args) => requestHandler(args[0], axios.patch(...args)),
    delete: (...args) => requestHandler(args[0], axios.delete(...args))
  }
}

/**
 * Generate URL query parameters string.
 * @param args Query arguments.
 */
export function query (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: {[key: string]: any}
): string {
  // Pagination options argument auto-decomposing into args.
  if ('pagination' in args &&
    'page' in args.pagination &&
    'size' in args.pagination) {
    args.page = args.pagination.page
    args.size = args.pagination.size
    delete args.pagination
  }

  const url = Object.entries(args)
    .map((arg) => {
      if (Array.isArray(arg[1])) {
        throw new Error(`query generator error: cannot implicitly resolve "${arg[0]}" array value`)
      }
      if (`${arg[1]}` === '[object Object]') {
        throw new Error(`query generator error: cannot implicitly resolve "${arg[0]}" object value`)
      }
      return `${encodeURIComponent(arg[0])}=${encodeURIComponent(arg[1])}`
    }).filter(arg => !arg.includes('undefined')).join('&')

  return url.length > 0
    ? `?${url}`
    : ''
}

/**
 * Add axios options header.
 * @param key Header key.
 * @param value Header value.
 */
export function header (key: string, value: string): (config: AxiosRequestConfig) => AxiosRequestConfig {
  return (config: AxiosRequestConfig) => {
    config.headers = config.headers || {}
    config.headers[key] = value
    return config
  }
}

/**
 * Extending the AxiosRequestConfig type with custom members
 */
export type AxiosRequestConfigExtended = AxiosRequestConfig & {
  /**
   * Use caching, the default is no cache.
   * Pass true for the default cache `new LRUCache({ maxAge: FIVE_MINUTES, max: 100 })` or an instance of LRUCache
   */
  useCache?: boolean | ICacheLike<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}

/**
 * Construct axios request config from many options.
 * A small request options middleware resolver.
 */
export function config (
  entries: Array<(config: AxiosRequestConfigExtended) => AxiosRequestConfigExtended>
): AxiosRequestConfigExtended {
  let config = {}
  for (const entry of entries) {
    config = entry(config)
  }
  return config
}
