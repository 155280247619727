import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { UnderwriterMutations } from '@/store/underwriters-module/mutation-types'
import store from '@/store'
import { Underwriter } from '@/models/underwriter'
import { defaultPaginationOptions } from '@/api/abstract/pagination'

@Module({ dynamic: true, store, name: 'UnderwritersModule' })
export default class UnderwritersModule extends VuexModule {
  public underwriters: Underwriter[] = []

  @Mutation
  private [UnderwriterMutations.LIST_UNDERWRITERS] (data: Underwriter[]): void {
    this.underwriters = data
  }

  @Action({ commit: UnderwriterMutations.LIST_UNDERWRITERS })
  public setUnderwriters (data: Underwriter[]): Underwriter[] {
    return data
  }

  @Action({ rawError: true })
  public async getUnderwriters () {
    const underwriters = await store.$apiClient.underwriters.list({ ...defaultPaginationOptions, size: 50 })
    this.setUnderwriters(underwriters.data)
  }

  get underwriterById () {
    return (id: string): Underwriter | undefined =>
      this.underwriters.find(underwriter => underwriter.id === id)
  }

  get underwriterBySlug () {
    return (slug: string): Underwriter | undefined =>
      this.underwriters.find(underwriter => underwriter.slug === slug)
  }
}
