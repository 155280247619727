import { AnswerMap, LayoutSection, Question } from '@/models/questionnaire'
import { Product, ProductType } from './product'
import { BillingType } from './underwriter'

export interface Quote {
  id: string
  productId: string
  underwriterSlug: string
  productSlug: string
  externalQuoteId: string
  externalQuoteReference: string
  policyPremium: string
  surcharge: string
  taxes: string
  monthsOfCoverage: number
  startDate: string | null
  endDate: string | null
  autoRenews: boolean
  paymentOptions: PaymentOptions[]
  occurrenceLimit: string
  aggregateLimit: string
  deductible: string
  status: QuoteStatus
  selected: boolean
  coverageAnswers: AnswerMap
  batchName: string
  billingType: BillingType,
  collectPayment: boolean,
  createdAt: string
  updatedAt: string
}

export enum InstallmentFrequency {
  Monthly = 'monthly',
  Yearly = 'yearly'
}

export enum QuoteStatus {
  QUEUED = 'queued',
  QUOTED = 'quoted',
  REFERRED = 'referred',
  REJECTED = 'rejected',
  FAILED = 'failed',
  DUPLICATED = 'duplicated',
  ELIMINATED = 'eliminated',
  OUTDATED = 'outdated'
}

export interface PaymentOptions {
  downpayment: number
  installmentFrequency: InstallmentFrequency
  installmentPayment: number
  installmentNumberOfTimes: number
}

export interface QuoteSelection {
  quoteId: string
  installmentFrequency: InstallmentFrequency
  product: string,
  downpayment: number,
  installmentNumberOfTimes: number,
  installmentPayment: number
}

export const ctaState = {
  SELECT: 'select',
  ADD_TO_CART: 'add_to_cart',
  IN_CART: 'in_cart',
  EDIT_REMOVE_FROM_CART: 'edit_remove_from_cart',
  UPDATE_POLICY: 'update_policy'
}

export const enum sortOptions {
  ASC = 'asc',
  DESC = 'desc'
}

export interface QuoteCoverages {
  quoteId: string,
  layout: LayoutSection[],
  questions: Question[],
  answers: AnswerMap
}

export interface QuoteProductInfo {
  quotes: Quote[],
  productList: Product[],
  productTypeList: ProductType[]
}
