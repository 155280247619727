import { Section } from '@/models/layout'
import { engine } from '@/engine'
import { AnswerMap, LayoutSection, Question } from '@/models/questionnaire'

/**
 * An "invisible" section is a section that has items in it, but due to the conditions of these questions, is invisible.
 */
const INVISIBLE_PREFIX = '__INVISIBLE__'

/**
 * Compute whether the given group is visible.
 * @param group The group to evaluate. Has `meta.composition === 'group'`
 * @param questions The questions for the application
 * @param answers Current answers
 */
export function isGroupVisible (group: LayoutSection, questions: Question[], answers: AnswerMap) {
  return group.items.some(questionId => {
    if (typeof questionId !== 'string') {
      // Future: support nested groups with visibility calc?
      return true
    }

    const q = questions.find(question => question.id === questionId)
    return (q && q.condition) ? engine.evaluate(q.condition, answers) : true
  })
}

function countVisibleSections (arr: string[]) {
  return arr.filter(sectionId => !sectionId.startsWith(INVISIBLE_PREFIX)).length
}

/**
 * An "invisible" section is a section that has items in it, but due to the conditions of these questions, is invisible.
 */
export function isInvisible (sectionId: string, visitedSections: string[]) {
  return visitedSections.includes(`${INVISIBLE_PREFIX}${sectionId}`)
}

/**
 * function controlling the flow of section for horaki engine,
 * skipping outer empty section and auto advancing to first
 * viable sections with questions to answer
 * @param visitedSections Warning: Mutated in place
 * @returns 'visitedSections' with at additional visible section added to it
 */
export function advanceVisibleSections (
  visitedSections: string[],
  sections: Section[],
  questions: Question[],
  answers: AnswerMap): string[] {
  for (const section of sections) {
    const firstMember = section.items[0]

    if (typeof firstMember === 'string' || firstMember.meta.composition === 'group') {
      // This is a section that can be visible - has questions in it
      if (visitedSections.includes(`${section.sectionId}`) ||
          visitedSections.includes(`${INVISIBLE_PREFIX}${section.sectionId}`)) {
        continue
      }

      // Does this section contain a visible item?
      const visible = section.items.some(item => {
        if (typeof item === 'string') {
          // Need to check if string questions are visible based on condition
          // issue when section includes subsection and individual questions, and none render out based on condition
          const q = questions.find(question => question.id === item)
          return (q && q.condition) ? engine.evaluate(q.condition, answers) : true
        } else if (item.meta.composition !== 'group') {
          return true
        } else {
          return isGroupVisible(item, questions, answers)
        }
      })

      visitedSections.push(`${visible ? '' : INVISIBLE_PREFIX}${section.sectionId}`)
      if (!visible) {
        continue
      }

      return visitedSections
    }

    // This is a section that contains other sections
    if (!visitedSections.includes(section.sectionId as string)) {
      visitedSections.push(section.sectionId as string)
    }

    const completedSections = countVisibleSections(visitedSections)
    advanceVisibleSections(visitedSections, section.items as Section[], questions, answers)

    if (countVisibleSections(visitedSections) !== completedSections) {
      return visitedSections // We've added a visible section - we're done
    }
  }

  return visitedSections
}
