
















































































import QuoteProductList from '@/components/quote/product-list.vue'
import { binderModule, cartModule } from '@/store/store-accessor'
import { defineComponent, computed, ref, reactive } from '@vue/composition-api'
import { LinkIcon } from 'vue-feather-icons'
import { ROUTES } from '@/router/routes'
import CartButton from '@/components/quote/cart-button.vue'
import FeedbackLauncher from '@/components/checkout/feedback/launcher.vue'
import IconBase from '@/components/icon-library/icon-base.vue'
import Save from '@/components/icon-library/save.vue'
import TheNavbarSubMenu from './TheNavbarSubMenu.vue'
import useLogo from '@/use/useLogo'
import useScreen from '@/use/useScreen'
import useScroll from '@/use/useScroll'
import VueRouter from 'vue-router'

export default defineComponent({
  name: 'TheNavbar',
  components: {
    CartButton,
    IconBase,
    LinkIcon,
    QuoteProductList,
    Save,
    TheNavbarSubMenu,
    FeedbackLauncher
  },
  setup (props, { root }) {
    const { logoContainerHeight, logoImage } = useLogo()
    const { scrollPosition } = useScroll()
    const { windowWidth } = useScreen()
    const router = reactive<VueRouter>(root.$router)
    const isQuotesPage = computed(() => router?.currentRoute.name === ROUTES.QUOTES)
    const showSupport = ref(true)

    const isQuotePageAndScrolled = computed(() => {
      return isQuotesPage.value &&
      scrollPosition.value > 160 &&
      windowWidth.value > 767
    })

    const hasItemsInCart = computed(() => {
      return cartModule.cartItems.length > 0
    })

    const toggleShareQuote = () => {
      binderModule.setShareQuote()
    }

    const toggleShowCart = () => {
      binderModule.setShowCart()
    }

    // SAVE FOR LATER
    const toggleSaveForLater = () => {
      binderModule.setSaveForLater()
    }
    const showSaveForLater = computed(() => {
      const allowedRoutes = [ROUTES.PRE_APPLICATION, ROUTES.APPLICATION, ROUTES.REVIEW, ROUTES.QUOTES]
      return allowedRoutes.includes(router?.currentRoute.name as string)
    })

    return {
      logoContainerHeight,
      logoImage,
      scrollPosition,
      isQuotesPage,
      showSaveForLater,
      showSupport,
      toggleSaveForLater,
      toggleShareQuote,
      isQuotePageAndScrolled,
      toggleShowCart,
      hasItemsInCart
    }
  }
})

