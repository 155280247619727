import { Header } from '@/api/abstract/resources/IQuoteProposalResource'
import { AnswerMap } from '@/models/questionnaire'
// @ts-ignore
import { createHash } from 'crypto'

export function getMonths () {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(x => x < 10
    ? { label: `0${x}`.toString(), key: `0${x}`.toString() }
    : { label: x.toString(), key: x.toString() })
}

export function getYears () {
  const currentYear = new Date().getFullYear()
  const yearsArr = []
  for (let year = currentYear; year <= currentYear + 20; year++) {
    yearsArr.push({ label: year.toString(), key: year.toString() })
  }

  return yearsArr
}

/**
 * Crete a simplistic debounce (cancel-able) function wrapper.
 *
 * @example
 * const debounce = debounceable()
 * const cancel = debounce(search, 300)
 * cancel()
 */
export const debounceable = () => {
  let timeout: number | null = null
  const clear = () => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
  }
  return (callback: () => void, time: number): () => void => {
    clear()
    timeout = window.setTimeout(callback, time)
    return clear
  }
}

export const cleanMask = (value: string, mask?: string) => {
  if (mask) {
    for (const c of mask.split('').filter(c => c !== '#' && c !== '_')) {
      value = value.replace(new RegExp(`\\${c}`, 'g'), '')
    }
  }
  return value
}

/**
 * Clamp value between the min and max.
 * @param value
 * @param min
 * @param max
 */
export function clamp (value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

/**
 * Regex sanitizing function
 *
 * @param   {string}  input
 *
 * @return  {[type]}
 */
export function sanitizeInput (input: string) {
  return input.replace(/[.*+?^${}()|[\]\\]/g, '')
}

export function shallowEqual (object1: AnswerMap, object2: AnswerMap): boolean {
  const keys = Object.keys(object1)

  for (const key of keys) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}

export function generateCompanyKey (answers: AnswerMap) {
  const hash = createHash('md5')
  const name = (answers.BusinessName as string).trim().replace(/\s+/g, '_').toLowerCase()
  const email = (answers.LinkEmail as string).trim().toLowerCase()
  return hash.update(`${name}_${email}`).digest('hex')
}

export function downloadFile (data: Blob, headers: Header) {
  const fileName = headers['content-disposition']?.split('filename=')[1]
  const fileType = headers['content-type']
  const fileBlob = new Blob([data], { type: fileType })

  const anchorTag = document.createElement('a')
  if (anchorTag.download !== undefined) {
    const url = URL.createObjectURL(fileBlob)
    anchorTag.setAttribute('download', fileName || 'proposal')
    anchorTag.setAttribute('href', url)
    anchorTag.style.visibility = 'hidden'
    document.body.appendChild(anchorTag)
    anchorTag.click()
    document.body.removeChild(anchorTag)
  }
}

/**
 * Is Object predicate.
 * @param value tested value
 * @return result of the test
 */
export function isObject (
  value: any
): boolean {
  return (
    value !== undefined &&
    value !== null &&
    typeof value === 'object' &&
    value.constructor === Object
  )
}
