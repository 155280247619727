import { defaultPaginationOptions } from '@/api/abstract/pagination'
import {
  extractCarrierData,
  extractCoverages,
  extractProductData,
  filterQuotes,
  getProductSlug,
  getUnderwriterSlug, isPolicyQueued,
  sortQuotes
} from '@/helpers/quote'
import { QuoteFilter, QuoteFilterItemsOptions } from '@/models/filter'
import { Policy } from '@/models/policy'
import { Product, ProductList, ProductType } from '@/models/product'
import { AnswerMap, Question } from '@/models/questionnaire'
import {
  InstallmentFrequency,
  Quote,
  QuoteCoverages,
  QuoteProductInfo,
  QuoteSelection,
  QuoteStatus,
  sortOptions
} from '@/models/quote'
import store from '@/store'
import { quoteModule, underwritersModule, cartModule, binderModule } from '@/store/store-accessor'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { QuoteMutations } from './mutation-types'

@Module({ dynamic: true, store, name: 'QuoteModule' })
export default class QuoteModule extends VuexModule {
  private products: Product[] = []
  private productTypes: ProductType[] = []
  private pollInterval = 5000
  private pollCeiling = 300000
  private quotes: Quote[] = []
  public quoteCoverages: QuoteCoverages[] = []
  private id?: string
  public loading = false
  private policy: Policy | null = null
  public sort: sortOptions = sortOptions.ASC
  private quoteSelection: QuoteSelection[] = []
  public quoteFilter: QuoteFilter = {
    carrier: {
      hint: ['Available carriers for your application'],
      title: 'Carrier',
      options: [],
      selection: []
    },
    policyCoverages: {
      hint: ['Coverages available from all quotes'],
      title: 'Policy offers',
      options: [],
      selection: []
    }
  }

  // Carrier selection for carrier filter-components
  public carriersSelected: string[] = []

  // Policy offers selection quote filter-components
  public policyCoverageSelected: string[] = []
  public productSelected = ''

  private acknowledgements: Question[] = []

  private coverages: Question[] = []

  public coverageSelection: AnswerMap = {}

  public quotePendingUpdate = false

  public quoteProductList: ProductList[] = []

  // #region Getters
  get coverageQuestions () {
    return this.coverages
  }

  get acknowledgmentsQuestions () {
    return this.acknowledgements
  }

  get quotesData (): Quote[] {
    return this.quotes
  }

  get quotesByStatus () {
    return (status: string): Quote[] => this.quotesData.filter(quote => quote.status === status)
  }

  get sortedFilteredQuotes (): Quote[] {
    const sortedQuotes = sortQuotes(this.quotes, this.sort)
    return filterQuotes(sortedQuotes,
      this.quoteCoverages,
      this.carriersSelected,
      this.policyCoverageSelected,
      this.productSelected)
  }

  get productList (): Product[] {
    return this.products
  }

  get productTypeList (): ProductType[] {
    return this.productTypes
  }

  get policyData (): Policy | null {
    return this.policy
  }

  get quoteSelections (): QuoteSelection[] {
    return this.quoteSelection
  }
  // #endregion

  // #region Mutations
  @Mutation
  private [QuoteMutations.SET_QUOTES] (data: Quote[]): void {
    this.quotes = data
  }

  @Mutation
  private [QuoteMutations.SET_PRODUCT_LIST] (data: Product[]): void {
    this.products = data
  }

  @Mutation
  private [QuoteMutations.SET_PRODUCT_TYPE_LIST] (data: ProductType[]): void {
    this.productTypes = data
  }

  @Mutation
  private [QuoteMutations.SET_ACKNOWLEDGEMENTS] (data: Question[]): void {
    this.acknowledgements = data
  }

  @Mutation
  private [QuoteMutations.SET_POLICIES] (data: Policy): void {
    this.policy = data
  }

  @Mutation
  private [QuoteMutations.UPDATE_QUOTE_SELECTIONS] (data: QuoteSelection[]): void {
    this.quoteSelection = data
  }

  @Mutation
  private [QuoteMutations.SET_COVERAGES] (data: Question[]): void {
    this.coverages = data
  }

  @Mutation
  private [QuoteMutations.UPDATE_COVERAGE_SELECTION] (data: AnswerMap): void {
    this.coverageSelection = {
      ...this.coverageSelection,
      ...data
    }
  }

  @Mutation
  private [QuoteMutations.SET_PENDING_UPDATES] (data: boolean): void {
    this.quotePendingUpdate = data
  }

  @Mutation
  private [QuoteMutations.SET_CARRIERS] (data: QuoteFilterItemsOptions[]): void {
    this.quoteFilter.carrier.options = data
  }

  @Mutation
  private [QuoteMutations.UPDATE_CARRIER_SELECTION] (data: string[]): void {
    this.carriersSelected = data
  }

  @Mutation
  private [QuoteMutations.UPDATE_POLICY_COVERAGE_SELECTION] (data: string[]): void {
    this.policyCoverageSelected = data
  }

  @Mutation
  private [QuoteMutations.APPLY_FILTER] (data:
    { carrier: string[], policyCoverages: string[] }): void {
    this.quoteFilter.carrier.selection = data.carrier
    this.quoteFilter.policyCoverages.selection = data.policyCoverages
  }

  @Mutation
  private [QuoteMutations.UPDATE_OCCURRENCE_LIMIT] (data: { selection: string, quoteId: string }): void {
    const quote = this.quotes.find(quote => quote.id === data.quoteId)
    if (quote) quote.occurrenceLimit = data.selection
  }

  @Mutation
  private [QuoteMutations.UPDATE_DEDUCTIBLE] (data: { selection: string, quoteId: string }): void {
    const quote = this.quotes.find(quote => quote.id === data.quoteId)
    if (quote) quote.deductible = data.selection
  }

  @Mutation
  private [QuoteMutations.UPDATE_COVERAGE] (data: { selection: string, quoteId: string, coverageId: string }): void {
    const quote = this.quotes.find(quote => quote.id === data.quoteId)
    if (quote) quote.coverageAnswers[data.coverageId] = data.selection
  }

  @Mutation
  private [QuoteMutations.UPDATE_SORT] (data: sortOptions): void {
    this.sort = data
  }

  @Mutation
  private [QuoteMutations.SET_COVERAGE_OPTIONS] (data: QuoteFilterItemsOptions[]): void {
    this.quoteFilter.policyCoverages.options = data
  }

  @Mutation
  private [QuoteMutations.SET_CARRIER_OPTIONS] (data: QuoteFilterItemsOptions[]): void {
    this.quoteFilter.carrier.options = data
  }

  @Mutation
  private [QuoteMutations.GET_QUOTE_COVERAGES] (data: QuoteCoverages): void {
    const coverages = this.quoteCoverages.filter(coverage => coverage.quoteId !== data.quoteId)
    this.quoteCoverages = [...coverages, data]
  }

  @Mutation
  private [QuoteMutations.SET_QUOTE_PRODUCTS_INFO] (data: ProductList[]): void {
    this.quoteProductList = data
  }

  @Mutation
  private [QuoteMutations.SET_QUOTE_STATUS_QUEUED] (data: string): void {
    for (const quote of this.quotes) {
      if (quote.id === data) {
        quote.status = QuoteStatus.QUEUED
      }
    }
  }

  @Mutation
  private [QuoteMutations.SET_QUOTE_PRODUCT_FILTER] (data: string): void {
    this.productSelected = data
  }

  @Mutation
  private [QuoteMutations.CLEAR_QUOTE_COVERAGES] (): void {
    this.quoteCoverages = []
  }
  // #endregion

  // #region Actions
  @Action({ commit: QuoteMutations.SET_PRODUCT_LIST, rawError: true })
  public async getProducts () {
    const products = await store.$apiClient.products.list({ ...defaultPaginationOptions, size: 50 })
    return products.data
  }

  @Action({ commit: QuoteMutations.SET_PRODUCT_TYPE_LIST, rawError: true })
  public async getProductTypes () {
    const productTypes = await store.$apiClient.productTypes.list({ ...defaultPaginationOptions, size: 50 })
    return productTypes.data
  }

  @Action({ commit: QuoteMutations.SET_QUOTES })
  private setQuotes (quotes: Quote[]): Quote[] {
    return quotes.map(quote => {
      quote.selected = this.quotes.find(oldQuote => oldQuote.id === quote.id)?.selected || false
      quote.underwriterSlug = getUnderwriterSlug(this.productList, underwritersModule.underwriters, quote.productId)
      quote.productSlug = getProductSlug(quote, this.productList, this.productTypeList)
      return quote
    })
  }

  @Action({ commit: QuoteMutations.SET_POLICIES })
  public setPolicies (data: Policy): Policy {
    return data
  }

  @Action({ commit: QuoteMutations.SET_COVERAGES })
  public setCoverages (data: Question[]): Question[] {
    return data
  }

  @Action({ rawError: true })
  public async fetchAcknowledgements ({ quoteId }: { quoteId: string }) {
    const res = await store.$apiClient.applications.quotes.acknowledgements.get(quoteId)
    if (res.questions.length > 0) {
      this.setAcknowledgements(res.questions)
    }
  }

  @Action({ rawError: true })
  public async fetchQuoteProposal ({ quoteId }: { quoteId: string }) {
    return await store.$apiClient.applications.quotes.proposals.get(quoteId)
  }

  @Action({ commit: QuoteMutations.SET_ACKNOWLEDGEMENTS })
  private setAcknowledgements (data: Question[]) {
    return data
  }

  @Action({ rawError: true })
  public async sendAcknowledgementsAnswers ({ quoteId, data }:
    { quoteId: string, data: Record<string, unknown> }): Promise<boolean> {
    await store.$apiClient.applications.quotes.acknowledgements.update(quoteId, data)
    return true
  }

  @Action({ rawError: true })
  public async createPolicy ({ quoteId, installmentFrequency, payment, skipPayment }: {
    quoteId: string, installmentFrequency: string, payment?: object, skipPayment?: boolean
  }
  ) {
    const policy = await store.$apiClient.applications.quotes.policies.create(
      quoteId,
      installmentFrequency as InstallmentFrequency,
      skipPayment,
      payment
    )
    this.setPolicies(policy)
    return policy
  }

  @Action({ rawError: true })
  public async retryPolicies ({ retryCounter, quoteId, policy }:
    { retryCounter: number, quoteId: string, policy: Policy }) {
    retryCounter++

    if (isPolicyQueued(policy) && retryCounter < (this.pollCeiling / this.pollInterval)) {
      setTimeout(async () => {
        policy = await store.$apiClient.applications.quotes.policies.get(policy.id)
        this.setPolicies(policy)
        this.retryPolicies({ retryCounter, quoteId, policy })
      }, this.pollInterval)
    }
  }

  @Action({ commit: QuoteMutations.UPDATE_QUOTE_SELECTIONS })
  private setQuoteSelections (data: QuoteSelection[]) {
    return data
  }

  @Action
  public settingQuoteSelections ({ quoteId, selectedPaymentOption }:
    { quoteId: string, selectedPaymentOption: InstallmentFrequency }) {
    // Get selected quote data
    const selectedQuote = this.quotesData.find(quote => quote.id === quoteId)
    if (selectedQuote) {
      // Get product slug from product list
      const product = quoteModule.productList.find(
        product => product.id === selectedQuote.productId
      )
      const productSlug = quoteModule.productTypeList.find(
        productType => productType.id === product?.productTypeId
      )?.slug

      const paymentOptionData = selectedQuote.paymentOptions.find(paymentOption =>
        paymentOption.installmentFrequency === selectedPaymentOption)

      if (paymentOptionData && productSlug) {
        const payload = {
          quoteId: quoteId,
          installmentFrequency: paymentOptionData.installmentFrequency,
          product: productSlug,
          downpayment: paymentOptionData.downpayment,
          installmentNumberOfTimes: paymentOptionData.installmentNumberOfTimes,
          installmentPayment: paymentOptionData.installmentPayment
        }

        const quoteSelectionClone: QuoteSelection[] = Object.assign([], this.quoteSelection)

        // Check if there's already a quote selected for this product
        // YES - then replace with new quote selection
        // No - then push selection

        // product selection check
        const currentProductSelected = quoteSelectionClone.find((quote: QuoteSelection) =>
          quote.product === payload.product
        )

        if (currentProductSelected) {
          for (const quote of quoteSelectionClone) {
            if (quote.product === payload.product) {
              quote.installmentFrequency = payload.installmentFrequency
              quote.quoteId = payload.quoteId
              quote.downpayment = payload.downpayment
              quote.installmentNumberOfTimes = payload.installmentNumberOfTimes
            }
          }
        } else {
          quoteSelectionClone.push(payload)
        }

        this.setQuoteSelections(quoteSelectionClone)
      }
    }
  }

  @Action
  public removeQuoteSelection (quoteId: string) {
    const newQuoteSelection = this.quoteSelections.filter(selection => selection.quoteId !== quoteId)

    this.setQuoteSelections(newQuoteSelection)
  }

  @Action({ rawError: true })
  public async saveCoverageSelection ({ id, answers }:
    { id: string, answers: AnswerMap }) {
    await store.$apiClient.applications.update(id, answers)
  }

  @Action({ commit: QuoteMutations.UPDATE_COVERAGE_SELECTION })
  public updateCoverageSelection (answers: AnswerMap) {
    return answers
  }

  @Action({ commit: QuoteMutations.SET_PENDING_UPDATES })
  public updatePendingUpdates (data: boolean) {
    return data
  }

  @Action({ commit: QuoteMutations.SET_CARRIERS })
  public setCarriers (data: QuoteFilterItemsOptions[]): QuoteFilterItemsOptions[] {
    return data
  }

  @Action({ commit: QuoteMutations.UPDATE_CARRIER_SELECTION })
  public updateCarrierSelected (data: string[]): string[] {
    return data
  }

  @Action({ commit: QuoteMutations.UPDATE_POLICY_COVERAGE_SELECTION })
  public updatePolicyCoverageSelected (data: string[]): string[] {
    return data
  }

  @Action({ commit: QuoteMutations.APPLY_FILTER })
  public applyFilter (data:
    { carrier: string[], policyCoverages: string[] }) {
    return data
  }

  @Action({ commit: QuoteMutations.UPDATE_OCCURRENCE_LIMIT })
  public updateOccurrenceLimit ({ selection, quoteId }: { selection: string, quoteId: string }) {
    return {
      selection,
      quoteId
    }
  }

  @Action({ commit: QuoteMutations.UPDATE_DEDUCTIBLE })
  public updateDeductible ({ selection, quoteId }: { selection: string, quoteId: string }) {
    return {
      selection,
      quoteId
    }
  }

  @Action({ commit: QuoteMutations.UPDATE_COVERAGE })
  public updateCoverage ({ selection, quoteId, coverageId }:
    { selection: string, quoteId: string, coverageId: string }) {
    return {
      selection,
      quoteId,
      coverageId
    }
  }

  @Action({ commit: QuoteMutations.UPDATE_SORT })
  public updateSort (sort: sortOptions): sortOptions {
    return sort
  }

  @Action({ commit: QuoteMutations.SET_COVERAGE_OPTIONS })
  public setCoverageFilterOptions (quoteCoverages: QuoteCoverages[]) {
    return extractCoverages(quoteCoverages)
  }

  @Action({ commit: QuoteMutations.SET_CARRIER_OPTIONS })
  public setCarrierFilterOptions (quotes: Quote[]) {
    return extractCarrierData(quotes, underwritersModule.underwriters)
  }

  /**
   * Get quote coverages by quote id
   */
  @Action({ rawError: true })
  public async getQuoteCoverages ({ quote }: { quote: Quote }) {
    const res = await store.$apiClient.applications.quotes.coverages.get(quote.id)
    const data = {
      quoteId: quote.id,
      layout: res.layout,
      questions: res.questions,
      answers: quote.coverageAnswers
    }
    this.setQuoteCoverageData(data)
    const totalCoverages = [
      ...this.quoteCoverages,
      data
    ]
    this.setCoverageFilterOptions(totalCoverages)
  }

  @Action({ commit: QuoteMutations.GET_QUOTE_COVERAGES })
  private setQuoteCoverageData (data: QuoteCoverages): QuoteCoverages {
    return data
  }

  @Action({ commit: QuoteMutations.SET_QUOTE_PRODUCTS_INFO })
  private setQuoteProductInfo (
    { quotes, productList, productTypeList }: QuoteProductInfo
  ): ProductList[] {
    return extractProductData(quotes, productList, productTypeList)
  }

  /**
   * Initial run of quotes, if already exist for application then it will list, otherwise it will create a new set
   */
  @Action({ rawError: true })
  public async initializeQuotes ({ id }: { id: string }) {
    if (this.id && this.id === id) {
      return this.quotes
    }

    try {
      // Try to list quotes for application if existing
      let { data: quotes } = await store.$apiClient.applications.quotes.list(
        id, defaultPaginationOptions
      )

      if (quotes.length === 0) {
        // If no quotes exists then create a set
        quotes = await store.$apiClient.applications.quotes.create(id)
      }

      await this.setQuotes(quotes)
      await this.updateFiltersByProductSlug(quotes[0].productSlug)
      await this.retryQueuedQuotes({ retryCounter: 1, id, quotes })
    } catch (err: any) {
      const skipLogging = err.isAxiosError && [400, 401].includes(err.response?.status)
      if (skipLogging === false) {
        Vue.$logger.error(`failed to load quotes - ${err}`)
      }
      throw err
    }
  }

  /**
   * Retrying queued quotes until status for all is either failed or quoted
   */
  @Action({ rawError: true })
  public async retryQueuedQuotes ({ retryCounter, id, quotes }:
    { retryCounter: number, id: string, quotes: Quote[] }): Promise<void> {
    const pending = quotes.some(quote => quote.status === QuoteStatus.QUEUED)
    const failure = quotes.every(quote => quote.status !== QuoteStatus.QUEUED &&
      quote.status !== QuoteStatus.QUOTED)

    // Grab coverage data for quoted quotes
    const quoted = quotes.filter(quote => quote.status === QuoteStatus.QUOTED)
    this.setQuoteProductInfo({ quotes: quoted, productList: this.productList, productTypeList: this.productTypeList })
    if (quoted.length > 0) {
      for (const quote of quoted) {
        const quoteHasCoverage = this.quoteCoverages.find(coverage => coverage.quoteId === quote.id)
        if (!quoteHasCoverage) {
          await this.getQuoteCoverages({ quote: quote })
        }
      }
      const productSlug = quoted[0].productSlug
      await this.updateFiltersByProductSlug(productSlug)
    }

    retryCounter++
    if (pending && !failure && retryCounter < (this.pollCeiling / this.pollInterval)) {
      await new Promise(resolve => setTimeout(resolve, this.pollInterval))
      const res = await store.$apiClient.applications.quotes.list(id, defaultPaginationOptions)
      const quotes = res.data
      this.setQuotes(quotes)
      return this.retryQueuedQuotes({ retryCounter, id, quotes })
    }
  }

  /**
   * Updating quote with new selection for coverages, getting a new price
   */
  @Action({ rawError: true })
  public async updateQuote ({ id, quote }: { id: string, quote: Quote }) {
    this.setQuoteStatusQueued(quote.id)
    const res = await store.$apiClient.applications.quotes.update(
      quote.id, quote.coverageAnswers
    )
    if (res.status === QuoteStatus.QUEUED) {
      const cloneQuotes = this.quotes.map(currQuote => (currQuote.id === quote.id ? res : currQuote))
      this.setQuotes(cloneQuotes.length ? cloneQuotes : [res])
      await this.retryQueuedQuote({ retryCounter: 1, id, quote: res })
    } else {
      await this.getQuoteCoverages({ quote })
    }
    if (res.status === QuoteStatus.QUOTED && binderModule.pendingQuoteAtCheckout) {
      const quoteUpdated = quoteModule.quotesData[0]
      const selectionData = { quoteId: quoteUpdated.id, selectedPaymentOption: InstallmentFrequency.Yearly }

      quoteModule.settingQuoteSelections(selectionData)
      cartModule.addItem({ quoteId: quoteUpdated.id, slug: quoteUpdated.productSlug })
      binderModule.setPendingQuoteCheckout(null)
    }
  }

  @Action({ rawError: true })
  public async checkForPendingQuote ({ id }: { id: string }) {
    await quoteModule.getProductTypes()
    await quoteModule.getProducts()
    await underwritersModule.getUnderwriters()
    await binderModule.resumeApplication()

    if (binderModule.pendingQuoteAtCheckout) {
      await quoteModule.updateQuote({
        id: id,
        quote: { id: binderModule.pendingQuoteAtCheckout || 0, coverageAnswers: binderModule.answers } as Quote
      })
    }
  }

  /**
   * Retry queued quote until status is failed or quoted
   */
  @Action({ rawError: true })
  public async retryQueuedQuote ({ retryCounter, id, quote }:
    { retryCounter: number, id: string, quote: Quote }) {
    retryCounter++
    if (quote.status === QuoteStatus.QUEUED && retryCounter < (this.pollCeiling / this.pollInterval)) {
      setTimeout(async () => {
        const res = await store.$apiClient.applications.quotes.get(quote.id)
        const cloneQuotes = this.quotes.map(currQuote => (currQuote.id === quote.id ? res : currQuote))
        this.setQuotes(cloneQuotes.length ? cloneQuotes : [res])
        await this.retryQueuedQuote({ retryCounter, id, quote: res })
      }, this.pollInterval)
    } else {
      await this.getQuoteCoverages({ quote: quote })
    }
    if (quote.status === QuoteStatus.QUOTED && binderModule.pendingQuoteAtCheckout) {
      const quoteUpdated = quoteModule.quotesData[0]
      const selectionData = { quoteId: quoteUpdated.id, selectedPaymentOption: InstallmentFrequency.Yearly }

      quoteModule.settingQuoteSelections(selectionData)
      cartModule.addItem({ quoteId: quoteUpdated.id, slug: quoteUpdated.productSlug })
      binderModule.setPendingQuoteCheckout(null)
    }
  }

  /**
   * Setting the quote status to QUEUED just before running an updated
   */
  @Action({ commit: QuoteMutations.SET_QUOTE_STATUS_QUEUED })
  public setQuoteStatusQueued (quoteId: string): string {
    return quoteId
  }

  /**
   * Resetting quotes in case the user took a wrong decision and would like to restart
   */
  @Action({ rawError: true })
  public async resetQuotes ({ id }: { id: string }) {
    if (this.id && this.id === id) {
      return this.quotes
    }

    const quotes = await store.$apiClient.applications.quotes.create(id)

    this.setQuotes(quotes)
    await this.retryQueuedQuotes({ retryCounter: 1, id, quotes })
  }

  /**
   * Update product node in quote filter
   *
   * @param   {string}  productSlug  [productSlug description]
   *
   * @return  {[type]}               [return description]
   */
  @Action({ commit: QuoteMutations.SET_QUOTE_PRODUCT_FILTER })
  public setQuoteProductFilter (productSlug: string) {
    return productSlug
  }

  /**
   * Update filters based on first product slug in quoted quotes array
   * This is used in a multi-product scenario where a user has the ability
   * to switch between multiple products on the quote screen
   *
   * @param   {Quote[]}  quotes  [Array of quoted quotes]
   *
   */
  @Action
  public async updateFiltersByProductSlug (productSlug: string) {
    const quotes = this.quotesData.filter(quotes => quotes.productSlug === productSlug)
    if (quotes.length > 0) {
      await this.setCarrierFilterOptions(quotes)
      await this.setQuoteProductFilter(productSlug)

      // To adjust coverage filter by product slug
      // 1. Get all quote ids by product slug
      // 2. Filter the quoteCoverages variable by those quote ids
      // 3. Call the setCoverageFilterOptions with the result set
      const coveragesArr = this.quoteCoverages.filter(coverage => {
        return quotes.map(quote => quote.id).includes(coverage.quoteId)
      })

      await this.setCoverageFilterOptions(coveragesArr)
    }
  }

  @Action({ commit: QuoteMutations.CLEAR_QUOTE_COVERAGES })
  private clearQuoteCoverages () {
    return null
  }

  /**
   * Clearing quotes and quote coverage when Restart Application is selected
   */
  @Action
  public async clearQuotes () {
    await this.setQuotes([])
    await this.clearQuoteCoverages()
  }
  // #endregion
}
