






























import { defineComponent, computed, ref, reactive } from '@vue/composition-api'
import { HeartIcon } from 'vue-feather-icons'
import Feedback from '@/components/checkout/feedback/index.vue'
import { binderModule } from '@/store/store-accessor'

export default defineComponent({
  name: 'FeedbackLauncher',
  components: { HeartIcon, Feedback },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root }) {
    const router = reactive(root.$router)
    const isShowing = ref(false)
    const isDrawerOpen = computed(() => {
      return binderModule.isDrawerOpen
    })

    const visibleForPage = computed(() => {
      const visibleOnPages = [
        'Pre-Application',
        'Application',
        'Quotes',
        'Acknowledgements',
        'Checkout',
        'Insured',
        'Review'
      ]
      return visibleOnPages.includes(router?.currentRoute && router?.currentRoute?.meta?.title)
    })

    const questionsSetForPage = computed(() => {
      return router?.currentRoute.name === 'Quotes' ? 'quotes' : 'application'
    })

    const toggle = () => {
      isShowing.value = !isShowing.value
    }
    return {
      isShowing,
      visibleForPage,
      questionsSetForPage,
      toggle,
      isDrawerOpen
    }
  }
})
