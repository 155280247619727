import _Vue from 'vue'
import { datadogLogs, LogsUserConfiguration, HandlerType } from '@datadog/browser-logs'

class DatadogLogger {
  install (Vue: typeof _Vue, options: LogsUserConfiguration): void {
    datadogLogs.init(options)

    datadogLogs.addLoggerGlobalContext('env', process.env.VUE_APP_APP_ENV)
    datadogLogs.addLoggerGlobalContext('service', 'briza-binder')

    if (process.env.NODE_ENV !== 'production') {
      // so that we can see logs in the console while developing
      datadogLogs.logger.setHandler(HandlerType.console)
    }

    Vue.$logger = datadogLogs.logger
    Vue.prototype.$logger = Vue.$logger
  }
}

export default new DatadogLogger()
