import { Questionnaire, AnswerMap } from './questionnaire'

export interface PreApplication {
  id: string
  questionnaire: Questionnaire
  answers: AnswerMap
  status: PreApplicationStatus,
  potentialAppetite?: Partial<Record<string, string[]>>
}

export enum PreApplicationStatus {
  Created = 'created',
  Completed = 'completed',
  Eliminated = 'eliminated'
}
