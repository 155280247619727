import Vue from 'vue'
import currency from '@/filters/currency'
import currencyWhole from '@/filters/currency-whole'
import phoneFormatter from '@/filters/phone'

Vue.filter('currency', currency)

Vue.filter('currencyWhole', currencyWhole)

Vue.filter('phoneFormatter', phoneFormatter)
