import {
  AnswerValue,
  Address,
  Question,
  QuestionOption,
  QuestionType
} from '@/models/questionnaire'
import Vue from 'vue'

const isString = (value: unknown): value is string => typeof value === 'string'

const parseBoolean = (value: string): boolean | null => {
  try {
    const booleanValue = JSON.parse(value)
    if (typeof booleanValue !== 'boolean') {
      return null
    }
    return booleanValue
  } catch (e: any) {
    return null
  }
}

const matchesKey = (value: string) => (option: QuestionOption) =>
  option.key === value ||
  option.key === parseBoolean(value) ||
  option.key === parseInt(value, 10)

const parseMultiSelect = (
  question: Question,
  value: unknown
): QuestionOption['key'][] | null => {
  if (!Array.isArray(value)) {
    return null
  }
  return value
    .map((value) => question.options?.find(matchesKey(value)))
    .filter((option): option is QuestionOption => option !== undefined)
    .map((option) => option.key)
}

const parseAddress = (value: unknown): Address | null => {
  if (typeof value !== 'object' || value === null) {
    return null
  }
  let possibleAddressValue = value as Record<string, unknown>
  const pairs: [string, string][] = [
    'street',
    'secondary',
    'city',
    'region',
    'postalCode',
    'county',
    'country'
  ]
    .map((key): [string, unknown] => [key, possibleAddressValue[key]])
    .filter((pair): pair is [string, string] => typeof pair[1] === 'string')

  if (pairs.length === 0) {
    return null
  }

  possibleAddressValue = pairs.reduce(
    (address, pair) => ({ ...address, [pair[0]]: pair[1] }),
    {}
  )

  const missingRequired = ['street', 'city', 'postalCode', 'country'].some(
    (requiredKey) => typeof possibleAddressValue[requiredKey] !== 'string'
  )

  if (missingRequired) {
    return null
  }

  return possibleAddressValue as Address
}

export const parseValue = (
  question: Question | undefined,
  value: unknown
): AnswerValue => {
  if (!question) {
    return null
  }
  switch (question.type) {
    case QuestionType.Number:
      return isString(value) ? parseInt(value, 10) : null
    case QuestionType.Decimal:
      return isString(value) ? parseFloat(value) : null
    case QuestionType.Boolean: {
      if (!isString(value)) {
        return null
      }
      try {
        const booleanValue = JSON.parse(value)
        if (typeof booleanValue !== 'boolean') {
          return null
        }
        return booleanValue
      } catch (e: any) {
        return null
      }
    }
    case QuestionType.MultiSelect: {
      const options = parseMultiSelect(question, value)
      return options && options.length > 0 ? options : null
    }
    case QuestionType.SingleLine:
    case QuestionType.MultiLine:
      return isString(value) ? value : null
    case QuestionType.ProductType: {
      const productTypes = parseMultiSelect(question, value)
      // allowing a single value for product types because we currently only support
      // a single product type.
      return productTypes && productTypes.length > 0
        ? productTypes.slice(0, 1)
        : null
    }
    case QuestionType.Select:
      if (!isString(value)) {
        return null
      }
      return question.options?.find(matchesKey(value))?.key ?? null
    case QuestionType.Date:
      if (!isString(value) || !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        return null
      }
      return value
    case QuestionType.DateYear:
      if (!isString(value) || !/^\d{4}$/.test(value)) {
        return null
      }
      return value
    case QuestionType.Address:
      return parseAddress(value)
    case QuestionType.ClassOfBusiness:
      if (!isString(value) || !/^\d{6}(-\d{1,2}){0,3}$/.test(value)) {
        return null
      }
      return value
    case QuestionType.Void:
    case QuestionType.Currency:
    case QuestionType.WcClassCode:
      return null
    default:
      Vue.$logger.error(`Unknown question type - ${question.type}`)
      return null
  }
}
