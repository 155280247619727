import { Component, Vue } from 'vue-property-decorator'
import { brokerageModule } from '@/store/store-accessor'
import { formatPhone } from '@/helpers/formatters'

@Component
export default class BrokerageMixin extends Vue {
  get brokerageName (): string | null {
    return brokerageModule.brokerage?.name ?? null
  }

  get brokerageEmail (): string | null {
    const contacts = brokerageModule.brokerage?.contacts?.filter(contact => contact.type === 'primary') ?? []
    return contacts.length > 0
      ? contacts[0].email
      : null
  }

  get brokeragePhone (): string | null {
    const contacts = brokerageModule.brokerage?.contacts ?? []
    return contacts.length > 0
      ? formatPhone(contacts[0].phone)
      : null
  }

  get brandColor (): string {
    return brokerageModule.brokerage?.brandColor ?? '#1578B2'
  }

  get brokerageLogoUrl (): string {
    return brokerageModule.brokerage?.logoUrl ?? ''
  }
}
