import Validator, { date, equal, exclude, include, length, pattern, present, range, year } from '@briza/wegood'
import { ValidationRule } from '@briza/wegood/types/rule'
import { ValidationError, ValidationMessage } from '@/models/validation-messages'
import {
  Address,
  AnswerMap,
  AnswerValue,
  Question,
  QuestionType,
  QuestionValidationRuleType
} from '@/models/questionnaire'
import { binderModule } from '@/store/store-accessor'

// TODO: Incorporate some or all ApplicationAnswerValidatorService from API into this file.
// See https://github.com/briza-insurance/briza-binder/issues/206

export function validateAddress (question: Question, answer: Address): ValidationError {
  let valid = true

  if (!answer.street.trim()) valid = false
  if (!answer.city.trim()) valid = false
  if (!answer?.region?.trim()) valid = false
  if (!answer.postalCode.trim() || answer.postalCode.length < 5) valid = false
  if (!answer.country.trim()) valid = false

  if (!valid) {
    return {
      valid: false,
      error: {
        field: question.id,
        message: 'Address information is incomplete or invalid'
      }
    }
  }

  // address is valid
  return {
    valid: true,
    error: {}
  }
}

function isAnswerMissing (question: Question, answer: AnswerValue | undefined) {
  return (answer === undefined ||
  answer === null ||
  answer === '' ||
  (Array.isArray(answer) && !answer.length))
}

export function isAnswerValid (question: Question, answer: AnswerValue | undefined): boolean {
  if (isAnswerMissing(question, answer)) {
    return !question.required || (question.type === QuestionType.MultiSelect && question.validation === undefined)
  }
  return true
}

export function validateQuestion (question: Question, answer: AnswerValue | undefined): ValidationError {
  if (!isAnswerValid(question, answer)) {
    return {
      valid: false,
      error: {
        field: question.id,
        message: question.type === QuestionType.Address
          ? 'Address information is incomplete or invalid.'
          : 'Please provide an answer'
      }
    }
  }

  if (isAnswerMissing(question, answer) && !question.required) {
    return {
      valid: true,
      error: {}
    }
  }

  // Custom validation for address field since there is no rule predefined
  // Check answer against Address Type and make sure all values are provided
  if (question.type === QuestionType.Address) {
    return validateAddress(question, answer as Address)
  }

  if (question.validation && question.validation.length > 0) {
    // API set validation messages, possibly undefined
    const validationMessages = question.ui.validation
    const rules: Array<ValidationRule> = question.validation.map(rule => {
      switch (rule.type) {
        case QuestionValidationRuleType.Present:
          return present(validationMessages?.present ?? 'Please provide an answer')
        case QuestionValidationRuleType.Range: {
          let rangeMessage = ''
          if (!rule.min && rule.max) {
            rangeMessage = `Please provide an answer that is at most ${rule.max.toLocaleString()}`
          } else if (rule.min && !rule.max) {
            rangeMessage = `Please provide an answer that is at least ${rule.min.toLocaleString()}`
          } else if (rule.min && rule.max) {
            rangeMessage = `Please provide an answer between ${rule.min.toLocaleString()}
            and ${rule.max.toLocaleString()}`
          }
          return range(rangeMessage, rule.min, rule.max)
        }
        case QuestionValidationRuleType.Equal:
          return equal(validationMessages?.equal ?? `Your answer must exactly be ${rule.value}`, rule.value)
        case QuestionValidationRuleType.Pattern:
          return pattern(
            validationMessages?.pattern ?? `Please provide an answer that matches this
            pattern "${rule.pattern}"`, rule.pattern as unknown as RegExp
          )
        case QuestionValidationRuleType.Length: {
          let lengthMessage = ''
          if (!rule.min && rule.max) {
            lengthMessage = `Please provide an answer no longer than ${rule.max} characters`
          } else if (rule.min && !rule.max) {
            lengthMessage = `Please provide an answer with at least ${rule.min} character${rule.min > 1 ? 's' : ''}`
          } else if (rule.min && rule.max) {
            lengthMessage = `Please provide an answer between ${rule.min} and ${rule.max} characters`
          }
          return length(lengthMessage, rule.min, rule.max)
        }
        case QuestionValidationRuleType.Exclude: {
          const exclusions = rule.exclusions ?? []
          return exclude(
            validationMessages?.exclude ?? `Your answer cannot be one of "${exclusions.join(', ')}"`,
            exclusions
          )
        }
        case QuestionValidationRuleType.Include: {
          const inclusions = rule.inclusions ?? []
          return include(
            validationMessages?.exclude ?? `Your answer must be one of "${inclusions.join(', ')}"`,
            inclusions
          )
        }
        case QuestionValidationRuleType.Date:
          // TODO: Be more specific about why the date is invalid, but hopefully the calendar picker
          // prevents the user from selecting an invalid date.
          return date(validationMessages?.date ||
            'Please select a valid date', rule.start, rule.end)
        case QuestionValidationRuleType.Year:
          return year(validationMessages?.year ||
            'Please enter a valid year', rule.start, rule.end)
        default:
          throw new Error(`validation rule ${rule.type} is missing`)
      }
    })

    // Forcing undefined answer to be empty strings during validation.
    // Wegood library does not validate against undefined values.
    answer = answer === undefined ? '' : answer
    const customValidator = new Validator(rules)
    const validationResult = customValidator.validate(answer)
    if (validationResult.valid) {
      return {
        valid: true,
        error: {}
      }
    }

    return {
      valid: false,
      error: {
        field: question.id,
        message: validationResult.errors[0]
      }
    }
  }

  // control is valid
  return {
    valid: true,
    error: {}
  }
}

export function validateQuestionByID (questionId: string): ValidationError {
  const questions = binderModule.application?.questionnaire?.questions
  const answer = binderModule.answers[questionId]
  const question = questions?.find(question => question.id === questionId)
  if (!question) {
    return {
      valid: false,
      error: {
        field: questionId,
        message: 'Question not found'
      }
    }
  }
  return validateQuestion(question, answer)
}

export function validateForm (
  form: HTMLFormElement,
  questions: Question[],
  answers: AnswerMap): ValidationMessage[] {
  const validationArr: ValidationMessage[] = []
  // get all questions Ids from form
  const formElements = [...form.querySelectorAll('[data-qid]')] as HTMLElement[]
  // make an array of unique questions Ids
  const questionsIds = [...new Set(formElements.map(el => el.dataset.qid))]
  // run validation rules for each question
  questionsIds.forEach(questionId => {
    const question = questions.find(question => question.id === questionId)
    if (!question) return
    const validation = validateQuestion(question, answers[questionId ?? ''])
    if (!validation.valid) validationArr.push(validation.error as ValidationMessage)
  })
  return validationArr
}

export function validateEmail (email: string): boolean {
  const patternValidator = new Validator([pattern('Enter a valid email address, for example: myname@example.com',
  '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z0-9]{2,}' as unknown as RegExp)])
  return patternValidator.valid(email)
}
