import { SectionUI, LayoutSection, SectionMeta, SectionItem } from './questionnaire'

export class Section implements LayoutSection {
  ui: SectionUI
  meta: SectionMeta
  items: SectionItem[]
  sectionId: string

  constructor (ui: SectionUI, meta: SectionMeta, item: SectionItem[], sectionId: string) {
    this.ui = ui
    this.meta = meta
    this.items = item
    this.sectionId = sectionId
  }
}

export class Layout {
  identifier: number
  sections: Section[]

  constructor (section: LayoutSection[]) {
    this.identifier = 1
    this.sections = this.parseLayout(section)
  }

  /**
   * Parse layout section to inject the section Id needed.
   * This id will be later used to identify each section for application visibility
   *
   * @param {Section[]} [sections=this.sections]
   * @returns {Section[]}
   * @memberof Layout
   */
  parseLayout (sections: LayoutSection[] = this.sections): Section[] {
    return sections.map((layoutSection: LayoutSection) => {
      const section = layoutSection as Section

      if (typeof section === 'object' && section.meta.composition === 'section') {
        section.sectionId = `S_${this.identifier}`

        this.identifier = this.identifier + 1
        this.parseLayout(section.items as Section[])
      }

      return section
    })
  }

  /**
   * Add all available section ids from (parseLayout) to a string array that will be used to hydrate
   * VUEX (visibleSections/HiddenSections)
   *
   * @param {Section[]} sections
   * @param accumulatedSectionIds - Accumulated section id array
   * @returns
   * @memberof Layout
   */
  availableSections (sections: Section[], accumulatedSectionIds: string[] = []): string[] {
    const sectionIds: string[] = accumulatedSectionIds

    for (const section of sections) {
      const sectionId = section.sectionId
      if (sectionId) {
        sectionIds.push(sectionId)
      }

      if (typeof section.items[0] === 'object' && section.items[0].meta.composition === 'section') {
        this.availableSections(section.items as Section[], sectionIds)
      }
    }

    return sectionIds
  }
}
