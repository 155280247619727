import { Component, Mixins } from 'vue-property-decorator'

import BrokerageMixin from './brokerage-mixin'

type CssProps = {
  [Key in string]: string
};

@Component
export default class StyleMixin extends Mixins(BrokerageMixin) {
  get cssProps (): CssProps {
    return {
      '--brand-color': this.brandColor
    }
  }
}
