import { CreditCard } from '@briza/collect'
import { Address } from './questionnaire'
import { InstallmentFrequency } from './quote'

export interface Policy {
  id: string
  externalPolicyId: string
  externalPolicyReference: string
  paymentFrequency: string
  directBillingInstructions: string | null
  directBillingUrl: string | null
  paidAt: string | null
  boundAt: string | null
  startDate: string | null
  endDate: string | null
  status: string
  createdAt: string | null
  updatedAt: string | null
}

export enum PolicyStatus {
  Queued = 'queued',
  Bound = 'bound',
  Failed = 'failed',
  ToBeBound = 'to-be-bound',
}

type Payment = CreditCard & {
  email: string
  address: Address
}

export type CreatePolicyRequest = {
  quoteId: string
  installmentFrequency: InstallmentFrequency
  payment?: Payment
  skipPayment: boolean
}
