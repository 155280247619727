import { computed, ref, watch } from '@vue/composition-api'
import { brokerageModule } from '@/store/store-accessor'
import useScreen from '@/use/useScreen'

export default function useLogo () {
  const { windowWidth } = useScreen()
  const logoHeight = ref(0)
  const logoImage = ref(new Image())
  const logoWidth = ref(0)

  /**
     * Adjusting logo container height based on viewport size
     * md and larger (767px)
     */
  const logoContainerHeight = computed(() => {
    if (windowWidth.value > 767) {
      return logoHeight.value > 100 ? '4rem' : '3rem'
    } else {
      return logoHeight.value > 100 ? '2.5rem' : '1.563rem'
    }
  })
  const logoUrl = computed(() => {
    return brokerageModule.brokerageLogoUrl
  })
  /**
   * Watching brokerage logo url assignment from the brokerage module
   * Assigning it to an image component and resizing after the fact
   */
  watch(logoUrl, (val) => {
    if (val) {
      logoImage.value.src = val
      setTimeout(() => {
        logoWidth.value = logoImage.value.width
        logoHeight.value = logoImage.value.height
      }, 150)
    }
  })

  return { logoContainerHeight, logoImage }
}
