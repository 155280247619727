
/**
 * Helper function to format a phone number per US style: (123) 436-7890
 * @param phone The phone number
 * @returns The formatted phone number, or 'phone' if formatting failed.
 */
export function formatPhone (phone: string | null | undefined): string {
  if (phone && /\d{10,10}/.test(phone)) {
    return phone.replace(/(\d{3,3})(\d{3,3})(\d{4,4})/, '($1) $2-$3')
  }

  return phone || ''
}
