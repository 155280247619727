


















import { Component, Watch, Mixins } from 'vue-property-decorator'
import TheNavbar from '@/components/the-navbar/index.vue'
import { BrokerageProfile, TrackingProviders } from './models/brokerage'
import { brokerageModule, binderModule } from './store/store-accessor'
import { pixelCode } from '@/scripts/facebook-pixel'
import { createGoogleTagManagerScript } from '@/scripts/google-tag-manager'
import { createGoogleAnalyticsScript } from '@/scripts/google-analytics'
import StyleMixin from './mixins/style-mixin'

const createScriptElement = (script: string, src?: string) => {
  const element = document.createElement('script')
  element.setAttribute('type', 'text/javascript')
  if (src) {
    element.setAttribute('src', src)
  }
  const inlineScript = document.createTextNode(script)
  element.appendChild(inlineScript)
  return element
}

@Component({
  name: 'App',
  components: {
    TheNavbar
  }
})
export default class App extends Mixins(StyleMixin) {
  @Watch('facebookPixelCode')
  onFacebookPixelCodeChanged (brokerPixelCode: string) {
    if (brokerPixelCode) {
      const script = pixelCode(brokerPixelCode)
      const element = createScriptElement(script)
      document.head.appendChild(element)
    }
  }

  @Watch('googleTagManagerId')
  onGoogleTagManagerIdChanged (googleTagManagerId: string) {
    if (googleTagManagerId) {
      const script = createGoogleTagManagerScript(googleTagManagerId)
      const element = createScriptElement(script)
      document.head.appendChild(element)
    }
  }

  @Watch('googleAnalyticsId')
  onGoogleAnalyticsIdChanged (googleAnalyticsId: string) {
    if (googleAnalyticsId) {
      const src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
      const srcElement = createScriptElement('', src)
      document.head.appendChild(srcElement)

      const script = createGoogleAnalyticsScript(googleAnalyticsId)
      const element = createScriptElement(script)
      document.head.appendChild(element)
    }
  }

  get brokerage (): BrokerageProfile | null {
    return brokerageModule.brokerage
  }

  get facebookPixelCode (): string | null | undefined {
    return this.brokerage?.scripts.find(
      (script) => script.provider === TrackingProviders.FacebookPixel
    )?.configuration.facebookId
  }

  get googleTagManagerId (): string | null | undefined {
    const script = this.brokerage?.scripts.find(
      (script) => script.provider === TrackingProviders.GoogleTagManager
    )
    return script?.configuration.googleTagManagerId
  }

  get googleAnalyticsId (): string | null | undefined {
    const script = this.brokerage?.scripts.find(
      (script) => script.provider === TrackingProviders.GoogleAnalytics
    )
    return script?.configuration.googleAnalyticsId
  }

  get isDrawerOpen () {
    const isOpen = binderModule.isDrawerOpen
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden'
      return {
        // position: 'fixed',
        // width: '100%',
        // margin: '0 auto'
        overflow: 'hidden'
      }
    } else {
      document.documentElement.style.overflow = 'auto'
      return {}
    }
  }
}
