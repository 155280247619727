





























import { defineComponent, computed, ref } from '@vue/composition-api'
import { StarIcon } from 'vue-feather-icons'
export default defineComponent({
  name: 'Rating',
  components: { StarIcon },
  props: {
    selectedRating: {
      type: Number,
      default: 0
    },
    ratingStates: {
      type: Array as () => string[],
      default: () => []
    }
  },
  emits: ['set-rating'],
  setup (props, { emit }) {
    const hoverRating = ref(0)
    const setRating = (rating: number, persist: boolean) => {
      hoverRating.value = rating
      if (persist) {
        emit('set-rating', rating)
      }
    }
    const activeStars = computed(() => {
      return hoverRating.value || props.selectedRating
    })
    return {
      activeStars, setRating
    }
  }
})
